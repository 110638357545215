import axios from 'axios'

const addBaseURL =
    process.env.NODE_ENV === `development`
        ? { baseURL: `https://api.getac.us` }
        : {}

export const getCountries = () => {
    return axios({
        ...addBaseURL,
        method: `get`,
        url: `/fn/LocationsAPI/countries`
    })
}

export const getStates = ({ geonameId }) => {
    return axios({
        ...addBaseURL,
        method: `get`,
        url: `/fn/LocationsAPI/states`,
        params: {
            geonameId
        }
    })
}

export const getCities = ({ geonameId, apo }) => {
    return axios({
        ...addBaseURL,
        method: `get`,
        url: `/fn/LocationsAPI/cities`,
        params: {
            geonameId,
            apo
        }
    })
}
