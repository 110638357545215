<template>
    <v-card :flat="!$vuetify.breakpoint.smAndDown">
        <br />
        <v-card-text class="text-center">
            <div v-if="locations === null">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                <div class="pt-5">Finding Nearby FedEx Office Locations</div>
            </div>
            <div v-else>
                <span v-for="(location, index) in locations" :key="index">
                    <h4>{{ location.company }}</h4>
                    <div>{{ firstLine(location) }}</div>
                    <div>{{ secondLine(location) }}</div>
                    <div>{{ location.firstName }}</div>
                    <br />
                    <v-divider v-if="index + 1 < locations.length" />
                    <br v-if="index + 1 < locations.length" />
                </span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: `PackagingLocations`,
    props: {
        locations: {
            type: [Array, null],
            default: null
        }
    },
    methods: {
        firstLine: function(location) {
            return (
                location.line1 + (location.line2 ? `, ${location.line2}` : ``)
            )
        },
        secondLine: function(location) {
            return `${location.city}, ${location.state} ${location.postalCode} ${location.country}`
        }
    }
}
</script>
