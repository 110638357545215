<template>
    <base-text-field v-mask="mask" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
    name: `BaseMask`,

    directives: { mask },

    props: {
        mask: { type: String, default: `` }
    }
}
</script>
