var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataMap.length)?_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.dataMap),function(main,m){return [_c('v-col',{key:m,staticClass:"pa-2",attrs:{"cols":"12","sm":"6"}},[_c('v-card',_vm._g(_vm._b({attrs:{"height":"100%"}},'v-card',_vm.$attrs,false),_vm.$listeners),[(main.mainTitle)?_c('v-card-title',[_vm._v(_vm._s(main.mainTitle))]):_vm._e(),(main.mainItems.length)?_c('v-card-text',_vm._l((main.mainItems),function(mainItem,mi){return _c('div',{key:mi,staticClass:"pb-4"},[(mainItem.subTitle)?_c('div',{staticClass:"\n                                    v-list-item__title\n                                    text--primary\n                                    pb-2\n                                "},[_vm._v(_vm._s(mainItem.subTitle))]):_vm._e(),(
                                    mainItem.subItems &&
                                    mainItem.subItems.length
                                )?_c('div',_vm._l((mainItem.subItems),function(subItem,b){return _c('div',{key:b},[(subItem.path)?_c('span',[(subItem.title)?_c('span',{staticClass:"text--primary"},[_vm._v(_vm._s(subItem.title)+":")]):_vm._e(),(
                                                _vm.itemValue(
                                                    _vm.allData,
                                                    subItem.path
                                                ) || subItem.override
                                            )?_vm._t(subItem.path,function(){return [_vm._v(" "+_vm._s(_vm.itemValue( _vm.allData, subItem.path ))+" ")]},{"root":_vm.allData,"item":_vm.itemValue(
                                                    _vm.allData,
                                                    subItem.path
                                                )}):_vm._e()],2):_vm._e()])}),0):_vm._e()])}),0):_vm._e()],1)],1)]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }