<template>
    <v-select
        ref="field"
        :data-cy="dataCy"
        outlined
        single-line
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
export default {
    name: `BaseSelect`,
    props: {
        dataCy: {
            type: String,
            default: ``
        }
    }
}
</script>
