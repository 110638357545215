<script>
import formRules from '@/utils/form_rules'
import { mdiEye, mdiEyeOff } from '@mdi/js'
export default {
    name: `ProblemForm`,
    inheritAttrs: false,
    props: {
        serialIsValid: Boolean,
        colMd: {
            type: String,
            default: ``
        }
    },

    data() {
        return {
            mdiEye,
            mdiEyeOff,
            formRules,
            eligibility: false,
            formValid: false,
            dataNotice: false,
            liability: false,
            showPassword1: false,
            showPassword2: false,
            formInput: {
                permissions: {
                    reimage: false,
                    biosFirmware: false
                },
                acc: {
                    acPower: false,
                    storage: false,
                    hardCase: false,
                    battery: false
                }
            }
        }
    },

    mounted() {
        this.setDefauts()
        this.emitValidation()
    },
    methods: {
        setDefauts() {
            this.formInput.permissions.reimage = false
            this.formInput.permissions.biosFirmware = false
            this.formInput.acc.acPower = false
            this.formInput.acc.storage = false
            this.formInput.acc.hardCase = false
            this.formInput.acc.battery = false
        },
        async resetForm() {
            this.$refs.form.reset()
            await this.$nextTick()
        },
        emitValidation(v) {
            this.$emit(`form-is-valid`, !!(this.formValid && this.serialIsValid))
        },
        async emitInput() {
            await this.$nextTick()
            this.$emit(`problemInput`, this.formInput)
        }
    }
}
</script>

<template>
    <v-form ref="form" v-model="formValid" @input="emitValidation">
        <div class="recycle-stepper-form">
            <v-row justify="space-between">
                <v-col cols="12" :md="colMd">
                    <v-card flat class="pr-5">
                        <slot name="serial-validation" />
                    </v-card>
                </v-col>
                <v-col cols="12" :md="colMd">
                    <v-card flat class="pr-5">
                        <div class="d-flex flex-wrap">
                            <v-img
                                class="mb-5"
                                max-height="150"
                                max-width="250"
                                src="https://www.scrapmonster.com/uploads/yardlogos/2016/12/1482208631.png"
                            ></v-img>
                            <base-info-card title="Eligibility and qualifications" color="primary">
                                <ul>
                                    <li
                                        >Getac will accept for recycling Getac branded computers, monitors, and
                                        peripherals product except products contaminated or suspected of being
                                        contaminated with chemicals, biological agents, or other substances not integral
                                        to the original new equipment or otherwise associated with normal office or
                                        household environments. Getac will not accept hazardous waste or batteries that
                                        are not an integral part of the system being recycling.
                                    </li>
                                    <li>
                                        Products must be packed and shipped in accordance with Getac’s instructions
                                        including, but not limited to, instructions regarding weight limitations and
                                        quantity and types of items to be recycled. Products will not be returned to
                                        senders. Shipped products must be described in the request for recycling. For
                                        information on packaging specifications, visit FedEx.com</li
                                    >
                                </ul>

                                <v-checkbox
                                    v-model="eligibility"
                                    data-cy="eligibility"
                                    :disabled="!serialIsValid"
                                    class="ma-5"
                                    label="I agree"
                                    :rules="[(v) => v === true]"
                                ></v-checkbox>
                            </base-info-card>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" :md="colMd">
                    <base-info-card title="Notice regarding data" color="primary">
                        <p>
                            Getac bears no responsibility for the loss or confidentiality of customer data on recycled
                            equipment. Before shipping equipment, the customer should:
                        </p>
                        <ul>
                            <li>Delete data on the hard-disk drives and any other storage devices in the products.</li>
                            <li>Back up or transfer any data prior to deletion.</li>
                            <li>Remove any removable media, such as diskettes, CDs, or PC Cards.</li>
                        </ul>
                        <p class="mt-2">
                            Once the hardware has been shipped, Getac cannot return it to the sender. Use of this
                            service constitutes a relinquishment of all rights of all rights to and in the hardware and
                            other computer materials sent to Getac’s authorized processor and/or recycler.
                        </p>
                        <v-checkbox
                            v-model="dataNotice"
                            class="ma-5"
                            data-cy="data-notice"
                            label="I agree"
                            :disabled="!serialIsValid"
                            :rules="[(v) => v === true]"
                        ></v-checkbox>
                    </base-info-card>
                </v-col>
                <v-col cols="12" :md="colMd">
                    <base-info-card title="Limitation of Liability" color="primary">
                        <p>
                            Getac provides this service as an accommodation and accepts no liability to any person for
                            any services performed in connection with the recycling offered in these terms, and
                            specifically disclaims liability for lost profits, loss of business or other consequential,
                            special, indirect, or punitive damages, even if advised of the possibility of such damages.
                        </p>
                        <p>
                            Getac is not responsible for any printing errors. Getac reserves the right to change,
                            without notice, the Terms and Conditions or end or modify the offer at any time.
                        </p>
                        <v-checkbox
                            v-model="liability"
                            data-cy="liability"
                            :disabled="!serialIsValid"
                            :rules="[(v) => v === true]"
                            class="ma-5"
                            label="I agree"
                        ></v-checkbox>
                    </base-info-card>
                    <base-info-card title="Consent" color="primary" />
                    <p>I want to recycle my product</p>
                    <v-radio-group
                        id="permissionsBios"
                        v-model="formInput.permissions.biosFirmware"
                        dense
                        :disabled="!serialIsValid"
                        row
                        :rules="[(v) => v === true]"
                        @change="emitInput"
                    >
                        <v-radio data-cy="form-approve-recycle" label="Yes" :value="true"></v-radio>
                        <v-radio data-cy="form-deny-recycle" label="No" :value="false"></v-radio>
                    </v-radio-group>
                    <p class="mt-5">
                        If you have any questions regarding recycling your product, please contact
                        <a href="mailto:gusa.recycleprogram@getac.com"> gusa.recycleprogram@getac.com</a>
                        or call us at
                        <a href="tel:+1-866-394-3822 (24/7)">+1-866-394-3822 (24/7)</a>
                    </p>
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<style>
.info-title-black {
    color: gray !important;
}
</style>
