<template>
    <div :key="userEmail">
        <base-info-card
            title="Upload Photo/Video"
            color="primary"
            subtitle="optional - Upload files pertaining to you rma that may help in resolving your issue."
        >
            <div
                class="d-flex align-top"
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
            >
                <v-card
                    flat
                    :width="$vuetify.breakpoint.smAndDown ? '' : '500'"
                >
                    <v-file-input
                        v-model="files"
                        multiple
                        label="File input"
                        outlined
                        dense
                        :loading="uploading"
                        type="file"
                        counter
                        :disabled="uploading"
                        accept="image/*, video/*"
                        :class="$vuetify.breakpoint.smAndDown ? '' : 'mr-5'"
                        data-cy="file-input"
                        show-size
                        messages="Limit 5 files per upload, max file size: 50mb"
                        @click:clear="cancelUpload"
                    ></v-file-input>
                    <v-alert
                        v-if="errorMessage"
                        dismissible
                        block
                        type="error"
                        :class="
                            !$vuetify.breakpoint.smAndDown
                                ? 'mt-2 mr-10'
                                : 'mt-2'
                        "
                    >
                        {{ errorMessage }}
                    </v-alert>
                </v-card>
                <base-btn
                    v-if="!uploading"
                    :block="$vuetify.breakpoint.smAndDown"
                    outlined
                    data-cy="file-input-upload-button"
                    :disabled="btnDisabled || uploading"
                    @click.native="submitFiles"
                >
                    Upload Files
                    <v-icon right dark>{{ mdiCloudUpload }}</v-icon>
                </base-btn>
                <base-btn
                    v-else
                    :block="$vuetify.breakpoint.smAndDown"
                    outlined
                    data-cy="file-input-upload-button"
                    @click.native="cancelUpload"
                >
                    Cancel Upload
                    <v-icon right dark>{{ mdiClose }}</v-icon>
                </base-btn>
            </div>

            <h4 v-if="successfulUploads.length">Files Attached:</h4>
            <ul class="pt-3">
                <li v-for="(f, i) in successfulUploads" :key="f.bucketFileName">
                    {{ f.fileName }} - <a @click="removeFile(i)">remove</a>
                </li>
            </ul>
        </base-info-card>
    </div>
</template>

<script>
import { fileUpload, axios } from '@/api'
import { mdiCloudUpload, mdiClose } from '@mdi/js'
export default {
    name: `FileUpload`,
    props: {
        btnDisabled: Boolean,
        userEmail: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            cancel: () => {},
            errorMessage: null,
            mdiClose,
            successfulUploads: [],
            mdiCloudUpload,
            uploading: false,
            files: [],
            legacyStatus: ``
        }
    },

    watch: {
        errorMessage(v) {
            if (v) {
                setTimeout(() => {
                    this.errorMessage = null
                }, 5000)
            }
        }
    },

    methods: {
        reset() {
            this.successfulUploads = []
            this.$emit(`fileNames`, [])
        },
        removeFile(i) {
            this.successfulUploads.splice(i, 1)
            this.$emit(`fileNames`, this.successfulUploads)
        },
        showError(e = {}) {
            this.errorMessage =
                e.data || e.message || `Error Uploading - Please Try Again`
        },
        cancelUpload() {
            this.cancel(`Operation canceled by the user.`)
        },
        async submitFiles() {
            if (!this.files.length)
                return (this.errorMessage = `Must Attach Files`)

            this.uploading = true
            this.errorMessage = null

            const formData = new FormData()

            for (let i = 0; i < this.files.length; i++) {
                const file = this.files[i]
                formData.append(`files[` + i + `]`, file)
            }

            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            try {
                this.cancel = source.cancel
                const { data } = await fileUpload(
                    {
                        formData
                    },
                    {
                        cancelToken: source.token
                    }
                )

                this.successfulUploads = [
                    ...this.successfulUploads,
                    ...data.map((e) => ({
                        fileName: e.originalname,
                        bucketFileName: e.bucketFileName
                    }))
                ]
                this.files = []
            } catch (e) {
                this.showError(e)
            } finally {
                this.uploading = false
                this.$emit(`fileNames`, this.successfulUploads)
            }
        }
    }
}
</script>
