<template>
    <v-container :style="containerStyle" :class="containerClass">
        <div
            :class="`container-bg mt-3 ${outerClass}`"
            :style="`margin-top: 1%; margin: auto; ${outerStyle}`"
        >
            <div class="container-fg" :class="innerClass" :style="innerStyle">
                <slot />
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'GusaContainer',
    props: {
        containerStyle: {
            type: String,
            required: false,
            default: ''
        },
        containerClass: {
            type: String,
            required: false,
            default: ''
        },
        innerStyle: {
            type: String,
            required: false,
            default: ''
        },
        outerStyle: {
            type: String,
            required: false,
            default: ''
        },
        innerClass: {
            type: String,
            required: false,
            default: ''
        },
        outerClass: {
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>
