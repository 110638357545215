function truncate(value, decimalPlaces = 2) {
    return (
        Math.trunc(value * Math.pow(10, decimalPlaces)) /
        Math.pow(10, decimalPlaces)
    )
}

function round(value) {
    return Math.round((value + Number.EPSILON) * 100) / 100
}

export { truncate, round }
