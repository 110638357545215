<template>
    <div v-if="offlineConfirmed" class="pa-10">
        <h3>
            The page timed out while loading. Are you sure you're still
            connected to the Internet?
        </h3>
    </div>
    <loading-view v-else />
</template>

<script>
import LoadingView from './_loading.vue'
import { pingK8 } from '@/api/index.js'
export default {
    page: {
        title: `Page timeout`,
        meta: [
            {
                name: `description`,
                content: `The page timed out while loading.`
            }
        ]
    },
    components: { LoadingView },
    data() {
        return {
            offlineConfirmed: false
        }
    },
    beforeCreate() {
        pingK8()
            .then(() => {
                window.location.reload()
            })
            .catch(() => {
                this.offlineConfirmed = true
            })
    }
}
</script>

<style lang="scss" module>
.title {
    text-align: center;
}
</style>
