var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"recycle-stepper-form"},[_c('div',[_c('base-info-card',{staticClass:"mx-0",attrs:{"title":"Request to Recycle Summary","color":"primary"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},_vm._l((_vm.col1),function(item){return _c('v-card',{key:item.title,staticClass:"mb-2"},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((item.headers),function(header){return _c('span',{key:header.k},[(
                                    _vm.itemValue(item.data, header.path) !==
                                    null
                                )?_c('v-list-item',[_c('v-list-item-content',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(header.title)+": ")]),_c('v-list-item-content',{staticClass:"\n                                        align-end\n                                        text-body-2\n                                        field-values\n                                    "},[_vm._v(" "+_vm._s(_vm.itemValue(item.data, header.path))+" ")])],1):_vm._e()],1)}),0),_c('v-card-text',{staticClass:"d-flex align-end flex-column",on:{"click":function($event){return _vm.editPage(item.stepId)}}},[_c('base-btn',{class:!_vm.$vuetify.breakpoint.smAndDown
                                    ? 'mr-10'
                                    : 'mt-1',attrs:{"data-cy":("btn-edit" + (item.title)),"block":_vm.$vuetify.breakpoint.smAndDown,"color":"primary","outlined":"","small":""}},[_vm._v(" Edit ")])],1)],1)}),1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._l((_vm.col2),function(item){return _c('v-card',{key:item.title,staticClass:"mb-2"},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((item.headers),function(header){return _c('span',{key:header.k},[(
                                    _vm.itemValue(item.data, header.path) !==
                                    null
                                )?_c('v-list-item',[_c('v-list-item-content',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(header.title)+": ")]),_c('v-list-item-content',{staticClass:"\n                                        align-end\n                                        text-body-2\n                                        field-values\n                                    "},[_vm._v(" "+_vm._s(_vm.itemValue(item.data, header.path))+" ")])],1):_vm._e()],1)}),0),_c('v-card-text',{staticClass:"d-flex align-end flex-column",on:{"click":function($event){return _vm.editPage(item.stepId)}}},[_c('base-btn',{class:!_vm.$vuetify.breakpoint.smAndDown
                                    ? 'mr-10'
                                    : 'mt-1',attrs:{"data-cy":("btn-edit" + (item.title)),"block":_vm.$vuetify.breakpoint.smAndDown,"color":"primary","outlined":"","small":""}},[_vm._v(" Edit ")])],1)],1)}),_c('div',{staticClass:"mb-0 pb-0 mt-10",class:("d-flex " + (_vm.$vuetify.breakpoint.smAndDown ? 'flex-wrap' : '') + " justify-end")},[_c('div',{staticClass:"mb-0 pb-0",class:!_vm.$vuetify.breakpoint.smAndDown
                                ? 'mt-5 mr-10'
                                : 'mt-1'},[_c('base-info-card',{staticClass:"mb-0 pb-0",attrs:{"title":"Confirm Your Information","color":"primary"}}),_c('v-checkbox',{attrs:{"data-cy":"form-confirm-recycle","rules":[function (v) { return v === true; }],"hide-details":"","label":"I am recycling my unit."},model:{value:(_vm.confirmRecycle),callback:function ($$v) {_vm.confirmRecycle=$$v},expression:"confirmRecycle"}}),_c('v-checkbox',{attrs:{"data-cy":"form-confirm-no-returns","rules":[function (v) { return v === true; }],"hide-details":"","label":"I understand my unit won't be returned once it's shipped."},model:{value:(_vm.confirmItsGone),callback:function ($$v) {_vm.confirmItsGone=$$v},expression:"confirmItsGone"}}),_c('v-checkbox',{attrs:{"data-cy":"form-confirm-rma-details","rules":[function (v) { return v === true; }],"hide-details":"","label":"I confirm the above information is accurate."},model:{value:(_vm.confirmData),callback:function ($$v) {_vm.confirmData=$$v},expression:"confirmData"}})],1)])],2)],1),_c('div',[_c('div',{staticClass:"fields-missing justify-end",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'},[(_vm.submissionError)?_c('v-alert',{class:!_vm.$vuetify.breakpoint.smAndDown
                            ? 'mt-2 mr-10'
                            : 'mt-2',attrs:{"block":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.submissionError || 'Please try again.')+" ")]):(_vm.showValidationErrors)?_c('v-alert',{class:!_vm.$vuetify.breakpoint.smAndDown
                            ? 'mt-2 mr-10'
                            : 'mt-2',attrs:{"block":"","type":"error"}},[_vm._v(" Missing Required Fields! ")]):_c('v-card',{class:!_vm.$vuetify.breakpoint.smAndDown
                            ? 'mt-2 mr-10'
                            : 'mt-2',attrs:{"flat":"","height":"72"}})],1)]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"justify-end",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'},[_c('div',[_c('base-btn',{class:!_vm.$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mt-1',attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"large":"","color":"primary","outlined":"","disabled":_vm.postingRma},on:{"click":_vm.goBack}},[_vm._v(" Back ")])],1),_c('div',{on:{"click":_vm.validateOnContinue}},[_c('base-btn',{class:!_vm.$vuetify.breakpoint.smAndDown
                                ? 'mr-10'
                                : 'mt-1',attrs:{"data-cy":"btn-continue-to-submit-rma","block":_vm.$vuetify.breakpoint.smAndDown,"disabled":!_vm.formValid,"color":"primary","large":"","loading":_vm.postingRma,"outlined":""},on:{"click":_vm.submitFinal}},[_vm._v(" Submit Request to Recycle ")])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }