import axios from 'axios'
import LogRocket from 'logrocket'
import { getInstance } from '@/auth'

axios.defaults.baseURL = process.env.VUE_APP_BASE_API

axios.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        // If the error is a 401 error, reauthenticate the user.
        // This could become problematic if the UI expects a 401 and performs an action based off the status
        // Eventually logging the user out via a open socket or EventSource from the server is the way to go.
        if (error && error.response && error.response.status === 401) {
            const authService = getInstance()
            if (authService) {
                await authService.getTokenSilently({
                    forceLoginOnError: true,
                    ignoreCache: true
                })
            }
        } else {
            return Promise.reject(error.response)
        }
    }
)

axios.interceptors.request.use(
    async (request) => {
        request.headers[`x-metadata`] = JSON.stringify({
            sessionUrl: LogRocket.sessionURL
        })

        const authService = getInstance()

        // update with latest auth token
        const token = authService
            ? await authService.getTokenSilently()
            : undefined

        if (token) {
            request.headers.Authorization = `Bearer ${token}`
        }

        return request
    },
    (error) => {
        return Promise.reject(error)
    }
)

export * from './ping.js'
export * from './create_rma.js'
export * from './user_account.js'
export * from './contact.js'
export * from './shipping.js'
export * from './location.js'
export * from './account_details.js'
export * from './quote.js'
export * from './dashboard.js'
export * from './drivers.js'

export { axios }
