import axios from 'axios'
export const verifySerialForRma = ({ serial }) => {
    return axios({
        method: `get`,
        url: `/rma-manager/rma/verify-serial`,
        params: {
            serial
        }
    }).catch((e) => {
        if (/404/gi.test(e.message)) {
            return {
                data: `404`
            }
        } else throw e
    })
}

export const postCreateRma = ({ payload }) => {
    return axios({
        method: `post`,
        url: `/rma-manager/rma/create`,
        data: payload
    })
}

export const fileUpload = ({ formData }, cancel) => {
    return axios({
        url: `/bucket-uploader/upload-rma-attachment`,
        method: `POST`,
        data: formData,
        ...cancel
    })
}

export const poUpload = ({ formData }, cancel) => {
    return axios({
        url: `/bucket-uploader/upload-to-common-gridfs`,
        method: `POST`,
        data: formData,
        ...cancel
    })
}

export const poDownload = ({ trackId }) => {
    return axios({
        url: `/bucket-uploader/download-from-common-gridfs`,
        method: `get`,
        params: { trackId },
        responseType: 'blob'
    })
}

export const createOowRma = ({ payload }) => {
    return axios({
        method: `post`,
        url: `/rma-manager/rma/create/oow`,
        data: payload
    })
}

export const processOowRma = ({ rmaNumber, serial }) => {
    return axios({
        method: `post`,
        url: `/rma-manager/rma/process/oow`,
        data: {
            rmaNumber,
            serial
        }
    })
}

export const cancelOowRma = ({ rmaNumber }) => {
    return axios({
        method: `post`,
        url: `/rma-manager/rma/cancel`,
        data: {
            rmaNumber
        }
    })
}
