import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getInstance } from '@/auth'
import LogRocket from 'logrocket'
import { relayStylePagination } from '@/utils/pagination'

Vue.use(VueApollo)

const httpLink = new HttpLink({
    uri: process.env.VUE_APP_BASE_API + `/graphql`
})

const forceLogin = async () => {
    const authService = getInstance()
    if (authService) {
        await authService.getTokenSilently({
            forceLoginOnError: true,
            ignoreCache: true
        })
    }
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions, message }) => {
            if (
                extensions?.response?.body?.errors[0]?.extensions?.code?.toLowerCase() ===
                    'unauthenticated' ||
                message?.toLowerCase() === 'invalid token'
            ) {
                forceLogin()
            }
        })
    }
    if (networkError) console.log(`[Network error]: ${networkError}`)
})

const authLink = setContext(async (_, { headers }) => {
    const _headers = {
        ...headers,
        'x-metadata': JSON.stringify({ sessionUrl: LogRocket.sessionURL })
    }

    const authService = getInstance()

    // update with latest auth token
    const token = authService ? await authService.getTokenSilently() : undefined

    if (token) {
        _headers.authorization = `Bearer ${token}`
    }

    return {
        headers: _headers
    }
})

const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    RMAsConnection: relayStylePagination(['search', 'sort']),
                    savedQuoteConnection: relayStylePagination([
                        'search',
                        'sort'
                    ]),
                    partsOrderBatchConnection: relayStylePagination([
                        'search',
                        'sort'
                    ])
                }
            },
            Customer: { merge: true },
            RMA: { keyFields: ['uid'] },
            Quote: { keyFields: ['uid'] },
            PrimaryReportedProblem: { keyFields: ['name'] },
            PartsOrderBatch: { keyFields: ['rmaBatchId'] },
            AccountOrganization: {
                keyFields: ['orgId'],
                fields: {
                    admins: {
                        merge: (_, incoming) => incoming
                    },
                    childrenOrg: {
                        merge: (_, incoming) => incoming
                    },
                    accounts: {
                        merge: (_, incoming) => incoming
                    }
                }
            }
        }
    })
})

export const apolloProvider = new VueApollo({
    defaultClient: apolloClient
})
