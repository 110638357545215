import Vue from 'vue'
import app from './app.vue'
import router from './router'
import store from './state/store'
import { Auth0Plugin } from './auth'
import vuetify from './plugins/vuetify'
import './plugins'
import { apolloProvider } from './plugins/apollo'

const startApp = async () => {
    Vue.config.productionTip = process.env.NODE_ENV === `production`

    if (process.env.VUE_APP_IS_E2E) {
        // Ensure tests fail when Vue emits an error.
        // Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
        window.__app__ = app
    }

    if (process.env.NODE_ENV === `development` && !process.env.VUE_APP_IS_E2E) {
        const { setUser } = await import(
            process.env.NODE_ENV === `development`
                ? `../mocks/servers/auth/set_user.js`
                : null
        )
        await setUser({
            client: process.env.VUE_APP_CLIENT_ID
        })
    }

    Vue.use(Auth0Plugin, {
        domain: process.env.VUE_APP_AUTH_API,
        clientId: process.env.VUE_APP_CLIENT_ID,
        onRedirectCallback: (appState) => {
            router
                .push(
                    appState && appState.targetUrl
                        ? appState.targetUrl
                        : window.location.pathname
                )
                .catch(() => {})
        }
    })

    new Vue({
        vuetify,
        router,
        store,
        apolloProvider,
        render: (h) => h(app)
    }).$mount(`#app`)
}

startApp()
