<template>
    <div>
        <base-info-card title="Upload PO" :style="darkMode ? 'color: white' : ''" color="primary">
            <div
                class="d-flex align-top"
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
            >
                <v-card
                    style="background-color: transparent"
                    flat
                    :width="$vuetify.breakpoint.smAndDown ? '' : '500'"
                >
                    <v-file-input
                        v-model="file"
                        label="File input"
                        outlined
                        dense
                        :loading="uploading"
                        type="file"
                        :disabled="uploading"
                        :class="$vuetify.breakpoint.smAndDown ? '' : 'mr-5'"
                        data-cy="file-input"
                        :dark="darkMode"
                        show-size
                        @click:clear="cancelUpload"
                    ></v-file-input>
                    <v-alert
                        v-if="errorMessage"
                        dismissible
                        block
                        type="error"
                        :class="
                            !$vuetify.breakpoint.smAndDown
                                ? 'mt-2 mr-10'
                                : 'mt-2'
                        "
                    >
                        {{ errorMessage }}
                    </v-alert>
                    <base-btn
                        v-if="!uploading"
                        :block="$vuetify.breakpoint.smAndDown"
                        outlined
                        data-cy="file-input-upload-button"
                        :disabled="uploading"
                        :dark="darkMode"
                        @click.native="submitFiles"
                    >
                        Upload File
                        <v-icon right dark>{{ mdiCloudUpload }}</v-icon>
                    </base-btn>
                    <base-btn
                        v-else
                        :block="$vuetify.breakpoint.smAndDown"
                        outlined
                        data-cy="file-input-upload-button"
                        @click.native="cancelUpload"
                    >
                        Cancel Upload
                        <v-icon right dark>{{ mdiClose }}</v-icon>
                    </base-btn>
                </v-card>
            </div>

            <h4 v-if="successfulUpload.file">File Attached:</h4>
            <ul v-if="successfulUpload.file" class="pt-3">
                <li>
                    {{ uploadedFileName }} - <a @click="removeFile">Remove</a> /
                    <a @click="downloadFile">Download</a>
                </li>
            </ul>
        </base-info-card>
    </div>
</template>

<script>
import { poUpload, poDownload, axios } from '@/api'
import { mdiCloudUpload, mdiClose } from '@mdi/js'
export default {
    name: `PoUploader`,
    props: {
        darkMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            cancel: () => {},
            errorMessage: null,
            mdiClose,
            successfulUpload: {},
            uploadedFileName: '',
            uploadedFileId: '',
            mdiCloudUpload,
            uploading: false,
            file: null,
            legacyStatus: ``
        }
    },

    watch: {
        errorMessage(v) {
            if (v) {
                setTimeout(() => {
                    this.errorMessage = null
                }, 5000)
            }
        }
    },

    methods: {
        reset() {
            this.successfulUpload = null
            this.$emit(`fileNames`, null)
        },
        removeFile() {
            this.successfulUpload = {}
            this.uploadedFileId = ''
            this.$emit(`fileName`, this.successfulUpload)
            this.$emit(`fileId`, this.successfulUpload)
        },
        showError(e = {}) {
            this.errorMessage =
                e.data || e.message || `Error Uploading - Please Try Again`
        },
        cancelUpload() {
            this.cancel(`Operation canceled by the user.`)
        },
        async downloadFile() {
            try {
                const result = await poDownload({
                    trackId: this.uploadedFileId
                })
                const fileUrl = URL.createObjectURL(result.data)
                const newFile = document.createElement('a')
                newFile.href = fileUrl
                newFile.download = this.uploadedFileName
                newFile.click()
            } catch (e) {
                console.error(e)
            }
        },
        async submitFiles() {
            if (this.file === null)
                return (this.errorMessage = `Must Attach Files`)

            this.uploading = true
            this.errorMessage = null

            const formData = new FormData()

            const file = this.file
            formData.append(`file`, file)

            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            try {
                this.cancel = source.cancel
                const { data } = await poUpload(
                    {
                        formData
                    },
                    {
                        cancelToken: source.token
                    }
                )

                this.successfulUpload = {
                    file: data[0].fileId
                }
                this.uploadedFileName = data[0].originalname
                this.uploadedFileId = data[0].fileId
                this.file = null
            } catch (e) {
                this.showError(e)
            } finally {
                this.uploading = false
                this.$emit(`fileId`, this.successfulUpload)
            }
        }
    }
}
</script>
