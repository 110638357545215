var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gusa-container',{attrs:{"container-class":"pt-0"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('img',{staticStyle:{"width":"130px","margin-bottom":"1%"},attrs:{"src":require("../../../assets/logos/getac-logo.svg")}}),_c('div',{staticClass:"text-h7 nav-font pl-3",staticStyle:{"margin-top":"1.3%"}},[_vm._v("CREATE RMA")])]),_c('v-divider',{staticStyle:{"margin-bottom":"1%","border-color":"gray"}}),_c('v-container',{staticClass:"download-label-container"},[(!!!_vm.rmaNumber)?_c('base-info-card',{staticClass:"my-2",attrs:{"title":"RMA has not been created","text":"If you feel you reached this page in error, please reach out to support","color":"primary"}}):_c('div',[_c('base-info-card',{staticClass:"my-2",attrs:{"data-cy":"success-rma-created","title":"Your RMA has been created","color":"primary"}}),(!!_vm.downloadLabelUrl)?_c('base-btn',{staticClass:"my-2",class:!_vm.$vuetify.breakpoint.smAndDown ? 'mr-1' : 'mt-1',attrs:{"data-cy":"btn-download-label","color":"primary","block":_vm.$vuetify.breakpoint.smAndDown,"outlined":""},on:{"click":_vm.downloadLabel}},[_vm._v(" Download Shipping Label ")]):_vm._e(),(!!_vm.downloadLabelUrl)?_c('base-body',{staticClass:"my-2",attrs:{"space":"3"}},[_vm._v(" A copy of this download link has also been sent to your email. ")]):_vm._e(),(!!_vm.commercialInvoiceUrl)?_c('base-btn',{staticClass:"my-2",class:!_vm.$vuetify.breakpoint.smAndDown ? 'mr-10' : 'mt-1',attrs:{"data-cy":"btn-download-commercial-invoice","color":"primary","block":_vm.$vuetify.breakpoint.smAndDown,"outlined":""},on:{"click":_vm.downloadCommercialInvoice}},[_vm._v(" Download Commercial Invoice ")]):_vm._e(),(_vm.advanceExchange)?_c('base-body',{attrs:{"data-cy":"advance-exchange-shipment","space":"3"}},[_vm._v(" A new device will be shipped to you with a return label to send your current device back. ")]):_vm._e(),(
                    !_vm.downloadLabelUrl &&
                    !_vm.commercialInvoiceUrl &&
                    _vm.shippingError
                )?_c('base-body',{attrs:{"data-cy":"error-on-shipment","space":"3"}},[_vm._v(" There was a error during shipping label creation. If you don't receive a label via email within 24 hours: please contact "),_c('a',{attrs:{"href":'mailto:getacsupport_us@getac.com?subject=Error during label creation for RMA# ' +
                        _vm.rmaNumber}},[_vm._v("Getac Service")]),_vm._v(" with your rma number, and a label will be provided. ")]):_vm._e(),(
                    !_vm.downloadLabelUrl &&
                    !_vm.commercialInvoiceUrl &&
                    !_vm.shippingError
                )?_c('base-body',{attrs:{"data-cy":"international-email","space":"3"}},[_vm._v(" If this is a international RMA, please contact "),_c('a',{attrs:{"href":'mailto:getacsupport_us@getac.com?subject=I need a international label for RMA# ' +
                        _vm.rmaNumber}},[_vm._v("Getac Service")]),_vm._v(" with your rma number, and a label will be provided. ")]):_vm._e()],1),_c('base-body',{staticClass:"my-2",attrs:{"space":"3"}},[_vm._v(" After your service request is complete, you can access a parts replaced repair history by clicking on "),_c('router-link',{attrs:{"to":"/your-account/rma-history"}},[_vm._v(" Your RMAs")]),_vm._v(" then clicking "),_c('base-btn',{staticClass:"mx-1",staticStyle:{"cursor":"default"},attrs:{"small":"","outlined":"","color":"blue darken-1"}},[_vm._v(" Repair Summary")]),_vm._v(" from inside RMA details window. ")],1),(!!_vm.rmaNumber)?_c('base-info-card',{attrs:{"data-cy":"rma-created-headline","title":("RMA# " + (_vm.createRmaResponse.rmaNumber) + " Summary"),"color":"primary"}},[_c('base-card-summary',{staticClass:"base-summary",attrs:{"tile":"","flat":"","data-map":_vm.summaryMap,"all-data":_vm.userInput},scopedSlots:_vm._u([{key:"rmaNumber",fn:function(){return [_vm._v(" "+_vm._s(_vm.createRmaResponse.rmaNumber)+" ")]},proxy:true},{key:"unitData.endDeviceWarranty",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateShort(item))+" ")]}}],null,false,2259785387)})],1):_vm._e(),_c('v-col',[_c('base-btn',{class:!_vm.$vuetify.breakpoint.smAndDown ? 'mr-10' : 'mt-1',attrs:{"color":"secondary","data-cy":"btn-create-another-rma","small":"","block":_vm.$vuetify.breakpoint.smAndDown,"outlined":""},on:{"click":function($event){return _vm.$emit('createNewRma')}}},[_vm._v(" Need to Create a new Service Request? ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }