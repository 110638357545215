<template>
    <div>
        <v-row v-if="dataMap.length" no-gutters>
            <template v-for="(main, m) in dataMap">
                <v-col :key="m" cols="12" sm="6" class="pa-2">
                    <v-card
                        v-bind="$attrs"
                        class=""
                        height="100%"
                        v-on="$listeners"
                    >
                        <v-card-title v-if="main.mainTitle">{{
                            main.mainTitle
                        }}</v-card-title>
                        <v-card-text v-if="main.mainItems.length">
                            <div
                                v-for="(mainItem, mi) in main.mainItems"
                                :key="mi"
                                class="pb-4"
                            >
                                <div
                                    v-if="mainItem.subTitle"
                                    class="
                                        v-list-item__title
                                        text--primary
                                        pb-2
                                    "
                                    >{{ mainItem.subTitle }}</div
                                >
                                <div
                                    v-if="
                                        mainItem.subItems &&
                                        mainItem.subItems.length
                                    "
                                >
                                    <div
                                        v-for="(
                                            subItem, b
                                        ) in mainItem.subItems"
                                        :key="b"
                                    >
                                        <span v-if="subItem.path">
                                            <span
                                                v-if="subItem.title"
                                                class="text--primary"
                                                >{{ subItem.title }}:</span
                                            >

                                            <slot
                                                v-if="
                                                    itemValue(
                                                        allData,
                                                        subItem.path
                                                    ) || subItem.override
                                                "
                                                :name="subItem.path"
                                                :root="allData"
                                                :item="
                                                    itemValue(
                                                        allData,
                                                        subItem.path
                                                    )
                                                "
                                            >
                                                {{
                                                    itemValue(
                                                        allData,
                                                        subItem.path
                                                    )
                                                }}
                                            </slot>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
import get from 'lodash/get'
export default {
    name: `CardSummary`,
    props: {
        allData: {
            type: Object,
            default() {
                return {}
            }
        },
        dataMap: {
            type: Array,
            default() {
                return [
                    // {
                    //     mainTitle: ``,
                    //     mainItems: [
                    //         {
                    //             subTitle: ``,
                    //             subItems: [
                    //                 {
                    //                     title: ``,
                    //                     path: ``
                    //                 }
                    //             ]
                    //         }
                    //     ]
                    // }
                ]
            }
        }
    },
    methods: {
        itemValue(data, path) {
            const value = get(data, path, null)
            if (typeof value === `boolean`) return String(value)
            else return value
        }
    }
}
</script>
