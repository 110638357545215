import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import LogRocket from 'logrocket'

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname)

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    redirectUri = window.location.origin,
    logoutUri = window.location.origin,
    ...options
}) => {
    if (instance) return instance

    const logoutKey = `GUSA-Logout`

    // clear old existing logout tokens
    window.localStorage.removeItem(logoutKey)

    // listen for localStorage logout event, and replace/redirect to home
    window.addEventListener('storage', (event) => {
        // eslint-disable-next-line no-undef
        if (event.storageArea !== localStorage) return
        if (event.key === logoutKey && event.newValue) {
            window.location.replace(logoutUri)
        }
    })

    // The 'instance' is simply a Vue object
    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                auth0Client: null,
                authOptions: {
                    issuer:
                        process.env.NODE_ENV === 'production'
                            ? options.domain
                            : undefined,
                    domain: options.domain,
                    client_id: options.clientId,
                    redirect_uri: redirectUri,
                    useFormData: true,
                    cacheLocation: 'localstorage',
                    prompt: 'consent',
                    advancedOptions: {
                        defaultScope:
                            'openid profile email roles address phone organization' // change the scopes that are applied to every authz request. **Note**: `openid` is always specified regardless of this setting
                    }
                }
            }
        },

        /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            // Create a new instance of the SDK client using members of the given options object
            this.init()
        },

        methods: {
            getFlatDiscountPercentage() {
                return this.user &&
                    this.user.flags &&
                    this.user.flags.rma &&
                    this.user.flags.rma.flatDiscountPercentage
                    ? this.user.flags.rma.flatDiscountPercentage
                    : null
            },
            userCanAccessClient() {
                const userPerms =
                    this.user && this.user.roles ? this.user.roles : []
                let useCanAccess = false
                userPerms.every((el) => {
                    const regex = RegExp(
                        `^${options.clientId.toLowerCase()}:`,
                        'g'
                    )
                    if (regex.test(el)) {
                        useCanAccess = true
                        return false
                    }
                    return true
                })
                return useCanAccess
            },
            userInOrganization() {
                return !!this.user && !!this.user.org && !!this.user.org.name
            },
            allRoles(reqPerm) {
                const userPerms =
                    this.user && this.user.roles ? this.user.roles : []
                const userPermsArray = Array.isArray(userPerms)
                    ? userPerms
                    : [userPerms]
                if (!reqPerm.length) return false
                if (Array.isArray(reqPerm))
                    return !!reqPerm.every((v) => userPermsArray.includes(v))
                else return !!userPermsArray.includes(reqPerm)
            },
            anyRole(reqPerm) {
                const userPerms =
                    this.user && this.user.roles ? this.user.roles : []
                const userPermsArray = Array.isArray(userPerms)
                    ? userPerms
                    : [userPerms]
                if (!reqPerm.length) return false
                if (Array.isArray(reqPerm))
                    return !!reqPerm.some((v) => userPermsArray.includes(v))
                else return !!userPermsArray.includes(reqPerm)
            },
            async loadUserProfile() {
                // eslint-disable-next-line no-undef
                const _user = await fetch(options.domain + '/me', {
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            'Bearer ' +
                            (await this.auth0Client.getTokenSilently())
                    }
                })
                const res = await _user.json()
                // if the user has any issue getting a token on profile load, force re-login
                if (res && res.error && res.error === 'invalid_token')
                    return this.loginWithRedirect({
                        redirect_uri: redirectUri
                    })
                this.user = Object.freeze(Object.assign({}, this.user, res))
            },
            // triggers a refresh of other browsers
            logoutOtherBrowsers() {
                window.localStorage.setItem(logoutKey, 'true')
            },

            /** Authenticates the user using the redirect method */
            loginWithRedirect(o) {
                return this.auth0Client.loginWithRedirect(o)
            },
            /** Returns all the claims present in the ID token */
            getIdTokenClaims(o) {
                return this.auth0Client.getIdTokenClaims(o)
            },

            /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
            async getTokenSilently(o) {
                if (this.auth0Client) {
                    return await this.auth0Client
                        .getTokenSilently(o)
                        .catch(async (err) => {
                            // if error getting access token, force user to relogin
                            if (
                                o &&
                                o.forceLoginOnError &&
                                err.error === 'login_required'
                            ) {
                                this.loginWithRedirect({
                                    redirect_uri: redirectUri
                                })
                            }
                        })
                }
            },

            /** Logs the user out and removes their session on the authorization server */
            async logout() {
                if (this.auth0Client) {
                    const idTokenClaims = await this.getIdTokenClaims()
                    this.auth0Client.logout({
                        id_token_hint: idTokenClaims
                            ? idTokenClaims.__raw
                            : undefined,
                        returnTo: logoutUri
                    })
                    if (!window.document.documentMode) {
                        // if user is not running IE do this
                        this.logoutOtherBrowsers()
                    }
                } else {
                    window.localStorage.clear()
                    window.location.replace(
                        `${options.domain}/v2/logout?returnTo=${logoutUri}`
                    )
                }
            },

            async init() {
                try {
                    this.auth0Client = await createAuth0Client(this.authOptions)

                    try {
                        // If the user is returning to the app after authentication..
                        if (
                            window.location.search.includes('code=') &&
                            window.location.search.includes('state=')
                        ) {
                            // handle the redirect and retrieve tokens
                            const { appState } =
                                await this.auth0Client.handleRedirectCallback()

                            // Notify subscribers that the redirect callback has happened, passing the appState
                            // (useful for retrieving any pre-authentication state)
                            onRedirectCallback(appState)
                        }
                    } catch (err) {
                        console.error(err)
                    } finally {
                        // Initialize our internal authentication state
                        this.isAuthenticated =
                            await this.auth0Client.isAuthenticated()
                        this.user = await this.auth0Client.getUser()
                        if (this.isAuthenticated) {
                            // Get identity of USER from /me endpoint
                            await this.loadUserProfile()
                            LogRocket.identify(this.user.sub, this.user)
                        }
                        this.loading = false
                    }
                } catch (err) {
                    if (err.error === 'server_error') {
                        this.logout()
                    } else console.error(err)
                }
            }
        }
    })

    return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useAuth0(options)
    }
}
