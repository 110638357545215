<template>
    <v-container style="color: white">
        <base-hero-simple />
        <div class="container-bg" style="margin-top: 1%; margin: auto">
            <div class="container-fg">
                <v-row justify="space-between">
                    <v-col cols="12" md="5">
                        <base-info-card
                            title="Environmental Certifications"
                            space="10"
                            color="primary"
                        >
                            For information on our environmental certifications
                            and other recycling details, please visit our main
                            site at
                            <a
                                href="https://www.getac.com/us/environmental-certifications/"
                                target="_blank"
                                >https://www.getac.com/us/environmental-certifications</a
                            >.
                        </base-info-card>
                        <base-info-card
                            title="Recycling Your Getac Batteries"
                            space="10"
                            color="primary"
                        >
                            The batteries used to power your portable Getac
                            products contain hazardous materials. While they
                            pose no risk to you during normal use, they should
                            never be disposed with other wastes. Getac provides
                            a free take-back service for recycling your
                            batteries from Getac products. To recycle the
                            battery, please contact us at (866) 394 - 3822 or by
                            email at
                            <a href="mailto:GUSA.RecycleProgram@getac.com"
                                >GUSA.RecycleProgram@getac.com</a
                            >. You may also drop off your batteries at any
                            Call2Recycle drop location. To locate a call2recycle
                            location near you call 1-877-2-Recycle or check the
                            website at
                            <a
                                target="_blank"
                                href="https://www.call2recycle.org"
                                >www.call2recycle.org</a
                            >.
                            <b
                                >You may also submit a request using the form
                                provided on this page.</b
                            >
                        </base-info-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <base-info-card
                            title="Submit Battery Recycle Request"
                            space="10"
                            color="primary"
                        >
                            <sf-form
                                v-if="$auth.isAuthenticated"
                                private-form
                                outlined
                                dense
                                subject="Battery recycle request"
                                case-type="Question"
                                hide-case-type
                                hide-subject
                                hide-serial-number
                                hide-rma-number
                            />
                            <div v-else>
                                Please
                                <a href="" @click.prevent @click="login"
                                    >sign in</a
                                >
                                to submit a service request
                            </div>
                        </base-info-card>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-container>
</template>

<script>
import sfForm from '@/components/support/sf_form'

export default {
    name: 'RecycleBattery',
    metaInfo: {
        title: 'Recycle Your Battery'
    },
    components: {
        sfForm
    },
    methods: {
        login() {
            this.$router.push(`/recycle-battery`)
        }
    }
}
</script>
