const state = {
    status: false,
    text: ``
}

const getters = {
    get: state => {
        return {
            status: state.status,
            text: state.text
        }
    }
}

const actions = {
    set({ commit }, value) {
        let text
        let status
        if (value === undefined || value === null || value.length === 0) {
            text = ``
            status = false
        } else {
            text = String(value)
            status = true
        }
        commit(`SET_TEXT`, { text: text })
        commit(`SET_STATUS`, { status: status })
    }
}

const mutations = {
    SET_TEXT(state, { text }) {
        state.text = text
    },
    SET_STATUS(state, { status }) {
        state.status = status
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
