import axios from 'axios'

export function validateAddress({
    firstName,
    lastName,
    line1,
    line2,
    city,
    state,
    postalCode,
    country
}) {
    return axios({
        method: `post`,
        url: `/shipping/location`,
        data: {
            firstName,
            lastName,
            line1,
            line2,
            city,
            state,
            postalCode,
            country
        }
    })
}

export function pickupAvailability({
    line1,
    line2,
    city,
    state,
    postalCode,
    country
}) {
    // getting tomorrows date in customers timezone
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    const year = tomorrow.getFullYear()
    const month = (`0` + (tomorrow.getMonth() + 1)).slice(-2)
    const day = (`0` + tomorrow.getDate()).slice(-2)

    return axios({
        method: `get`,
        url: `/shipping/pickup`,
        params: {
            line1,
            line2,
            city,
            state,
            postalCode,
            country,
            requestedDate: `${year}-${month}-${day}`
        }
    })
}

export function createPickup({
    firstName,
    lastName,
    company,
    line1,
    line2,
    city,
    state,
    postalCode,
    country,
    requestedDate,
    startTime,
    endTime,
    numberOfPackages
}) {
    return axios({
        method: `post`,
        url: `/shipping/pickup`,
        data: {
            firstName,
            lastName,
            company,
            line1,
            line2,
            city,
            state,
            postalCode,
            country,
            requestedDate,
            startTime,
            endTime,
            numberOfPackages
        }
    })
}

export function dropOffLocations({
    firstName,
    lastName,
    line1,
    line2,
    city,
    state,
    postalCode,
    country
}) {
    return axios({
        method: `get`,
        url: `/shipping/offices`,
        params: {
            firstName,
            lastName,
            line1,
            line2,
            city,
            state,
            postalCode,
            country
        }
    })
}
