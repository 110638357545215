<template>
    <div>
        <base-info-card
            v-if="!hideTitle"
            :title="title"
            :subtitle="subtitle"
            color="primary"
        />
        <v-container>
            <v-row justify="space-between">
                <v-col cols="12" md="5">
                    <base-text-field
                        v-model="user.firstName"
                        hide-details
                        dense
                        label="First Name"
                    />
                </v-col>
                <v-col>
                    <base-text-field
                        v-model="user.lastName"
                        hide-details
                        dense
                        label="Last Name"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <base-text-field v-model="user.email" dense label="Email" />
                    <base-text-field v-model="user.phone" dense label="Phone" />
                    <base-text-field
                        v-model="user.company"
                        dense
                        label="Company"
                    />
                    <base-text-field
                        v-model="user.subject"
                        dense
                        label="Subject"
                    />
                    <base-select
                        v-model="reason"
                        dense
                        :items="reasonList"
                        label="Primary Fault"
                    ></base-select>

                    <base-textarea
                        v-model="desc"
                        label="Your Need & Description"
                    />
                    <v-alert
                        v-if="error"
                        block
                        small
                        type="error"
                        :class="
                            !$vuetify.breakpoint.smAndDown
                                ? 'mt-2 mr-10'
                                : 'mt-2'
                        "
                    >
                        Error submitting form. Please try again.
                    </v-alert>
                    <base-btn
                        v-if="!hideButton"
                        max-width="400"
                        :class="alignSide"
                        :loading="submitted"
                        :color="!theme.isDark ? 'accent' : 'white'"
                        outlined
                        :block="$vuetify.breakpoint.smAndDown"
                        @click="postData"
                    >
                        Send message
                    </base-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { postContactForm } from '@/api'

export default {
    name: `BaseContactForm`,

    inject: [`theme`],

    props: {
        hideButton: Boolean,
        hideTitle: Boolean,
        subtitle: String,
        title: {
            type: String,
            default: `Contact Us`
        },
        alignButton: {
            type: String,
            default: `left`
        },
        user: {
            type: Object,
            default: () => ({
                email: ``
            })
        }
    },
    data() {
        return {
            error: false,
            submitted: false,
            firstName: this.user.firstName || ``,
            lastName: this.user.lastName || ``,
            phone: this.user.phone || ``,
            company: this.user.company || ``,
            origin: `Getac Service Portal`,
            reasonList: [`broken`, `software`],
            reason: ``,
            subject: ``,
            desc: ``
        }
    },
    computed: {
        alignSide() {
            const side = this.alignButton === `right` ? `ml` : `mr`
            return `${side}-auto`
        }
    },

    methods: {
        async postData() {
            this.submitted = true
            try {
                await postContactForm({
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phone: this.phone,
                    company: this.company,
                    origin: this.origin,
                    reason: this.reason,
                    subject: this.subject,
                    desc: this.desc
                })
                this.$emit(`messageSent`, true)
            } catch (error) {
                this.error = true
            } finally {
                this.submitted = false
            }
        }
    }
}
</script>
