<script>
import get from 'lodash/get'

export default {
    name: `ConfirmstepId`,
    props: {
        contactData: { type: Object, default: () => {} },
        addressData: { type: Object, default: () => {} },
        packingData: { type: Object, default: () => {} },
        problemData: { type: Object, default: () => {} },
        unitData: { type: Object, default: () => {} },
        overrideData: { type: Object, default: () => {} },
        userHasOverride: Boolean,
        postingRma: Boolean,
        submissionError: {
            default: ``,
            type: String
        }
    },

    data() {
        return {
            showValidationErrors: false,
            confirmData: false,
            confirmRecycle: false,
            confirmItsGone: false,
            formValid: false,
            addressHeaders: [
                { path: `addressData.line1`, title: `Address` },
                { path: `addressData.line2`, title: `Address 2` },
                { path: `addressData.city`, title: `City` },
                { path: `addressData.country`, title: `Country` },
                {
                    path: `addressData.state`,
                    title: `State/Province`
                },
                { path: `addressData.postalCode`, title: `Postal Code` }
            ],

            contactHeaders: [
                { path: `contactData.firstName`, title: `First Name` },
                { path: `contactData.lastName`, title: `Last Name` },
                { path: `contactData.company`, title: `Company` },
                { path: `contactData.position`, title: `Position` },
                { path: `contactData.email`, title: `Email` },
                { path: `contactData.phone`, title: `Phone#` }
            ],

            packingHeaders: [
                {
                    path: `packingData.havePackaging`,
                    title: `I have packaging`
                },
                { path: `packingData.requestBox`, title: `I need a box` }
            ],

            unitHeaders: [
                { path: `unitData.serial`, title: `Serial Number` },
                { path: `unitData.model`, title: `Model` }
            ],
            overrideHeaders: [
                { path: `overrideData.tpm`, title: `Destination TPM` },
                { path: `overrideData.rmaType`, title: `RMA Type` }
            ],

            problemHeaders: [
                { path: `problemData.primaryFault`, title: `Selected Problem` },
                { path: `problemData.problem`, title: `Sub Problem` },
                {
                    path: `problemData.shippingWithDevice`,
                    title: `Shipping Items`
                },
                {
                    path: `problemData.permissions.reimage`,
                    title: `Re Image Approval?`
                },
                {
                    path: `problemData.permissions.biosFirmware`,
                    title: `Upgrade BIOS?`
                },
                { path: `problemData.windowsUserName`, title: `Windows UN` },
                { path: `problemData.custReference`, title: `Ref No#` },
                { path: `problemData.assetTag`, title: `Asset Tag` },
                { path: `problemData.initialNotes`, title: `User Notes` }
            ],
            colMd: `6`,
            title: `Complete`
        }
    },

    computed: {
        inputData() {
            return {
                contactData: this.contactData,
                addressData: this.addressData,
                packingData: this.packingData,
                problemData: this.problemData,
                unitData: this.unitData,
                overrideData: this.overrideData
            }
        },
        col1() {
            return [
                {
                    data: this.inputData || {},
                    title: `Unit Description`,
                    headers: this.unitHeaders,
                    stepId: `problem`
                },
                {
                    data: this.inputData || {},
                    title: `Contact Information`,
                    headers: this.contactHeaders,
                    stepId: `contact`
                }
            ]
        },
        col2() {
            const col = [
                {
                    data: this.inputData || {},
                    title: `Your Address`,
                    headers: this.addressHeaders,
                    stepId: `contact`
                }
            ]
            if (this.userHasOverride) {
                col.push({
                    data: this.inputData || {},
                    title: `Admin Overrides`,
                    headers: this.overrideHeaders,
                    stepId: `override`
                })
            }
            return col
        }
    },

    methods: {
        async resetForm() {
            this.$refs.form.reset()
            await this.$nextTick()
        },
        editPage(v) {
            this.confirmData = false
            this.$emit(`editPage`, v)
        },
        goBack() {
            this.confirmData = false
            this.$emit(`back`)
        },
        submitFinal() {
            this.submitted = true
            this.$emit(`submitRma`)
        },

        itemValue(data, path) {
            return get(data, path, null)
        },

        validateOnContinue() {
            this.$refs.form.validate()
            if (!this.formValid) this.showValidationErrors = true
            const self = this
            setTimeout(() => {
                self.showValidationErrors = false
            }, 4000)
        }
    }
}
</script>

<template>
    <v-form ref="form" v-model="formValid">
        <div class="recycle-stepper-form">
            <div>
                <base-info-card
                    class="mx-0"
                    title="Request to Recycle Summary"
                    color="primary"
                />
            </div>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card v-for="item in col1" :key="item.title" class="mb-2">
                        <v-card-title class="text-subtitle-2">
                            {{ item.title }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-list dense>
                            <span
                                v-for="header in item.headers"
                                :key="header.k"
                            >
                                <v-list-item
                                    v-if="
                                        itemValue(item.data, header.path) !==
                                        null
                                    "
                                >
                                    <v-list-item-content class="text-body-2">
                                        {{ header.title }}:
                                    </v-list-item-content>
                                    <v-list-item-content
                                        class="
                                            align-end
                                            text-body-2
                                            field-values
                                        "
                                    >
                                        {{ itemValue(item.data, header.path) }}
                                    </v-list-item-content>
                                </v-list-item>
                            </span>
                        </v-list>

                        <v-card-text
                            class="d-flex align-end flex-column"
                            @click="editPage(item.stepId)"
                        >
                            <base-btn
                                :data-cy="`btn-edit${item.title}`"
                                :block="$vuetify.breakpoint.smAndDown"
                                :class="
                                    !$vuetify.breakpoint.smAndDown
                                        ? 'mr-10'
                                        : 'mt-1'
                                "
                                color="primary"
                                outlined
                                small
                            >
                                Edit
                            </base-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card v-for="item in col2" :key="item.title" class="mb-2">
                        <v-card-title class="text-subtitle-2">
                            {{ item.title }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-list dense>
                            <span
                                v-for="header in item.headers"
                                :key="header.k"
                            >
                                <v-list-item
                                    v-if="
                                        itemValue(item.data, header.path) !==
                                        null
                                    "
                                >
                                    <v-list-item-content class="text-body-2">
                                        {{ header.title }}:
                                    </v-list-item-content>
                                    <v-list-item-content
                                        class="
                                            align-end
                                            text-body-2
                                            field-values
                                        "
                                    >
                                        {{ itemValue(item.data, header.path) }}
                                    </v-list-item-content>
                                </v-list-item>
                            </span>
                        </v-list>

                        <v-card-text
                            class="d-flex align-end flex-column"
                            @click="editPage(item.stepId)"
                        >
                            <base-btn
                                :data-cy="`btn-edit${item.title}`"
                                :block="$vuetify.breakpoint.smAndDown"
                                :class="
                                    !$vuetify.breakpoint.smAndDown
                                        ? 'mr-10'
                                        : 'mt-1'
                                "
                                color="primary"
                                outlined
                                small
                            >
                                Edit
                            </base-btn>
                        </v-card-text>
                    </v-card>
                    <div
                        class="mb-0 pb-0 mt-10"
                        :class="`d-flex ${
                            $vuetify.breakpoint.smAndDown ? 'flex-wrap' : ''
                        } justify-end`"
                    >
                        <div
                            class="mb-0 pb-0"
                            :class="
                                !$vuetify.breakpoint.smAndDown
                                    ? 'mt-5 mr-10'
                                    : 'mt-1'
                            "
                        >
                            <base-info-card
                                class="mb-0 pb-0"
                                title="Confirm Your Information"
                                color="primary"
                            >
                            </base-info-card>
                            <v-checkbox
                                v-model="confirmRecycle"
                                data-cy="form-confirm-recycle"
                                :rules="[(v) => v === true]"
                                hide-details
                                label="I am recycling my unit."
                            />
                            <v-checkbox
                                v-model="confirmItsGone"
                                data-cy="form-confirm-no-returns"
                                :rules="[(v) => v === true]"
                                hide-details
                                label="I understand my unit won't be returned once it's shipped."
                            />
                            <v-checkbox
                                v-model="confirmData"
                                data-cy="form-confirm-rma-details"
                                :rules="[(v) => v === true]"
                                hide-details
                                label="I confirm the above information is accurate."
                            />
                        </div>
                    </div>
                </v-col>
            </v-row>

            <div>
                <div
                    class="fields-missing justify-end"
                    :class="
                        $vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'
                    "
                >
                    <v-alert
                        v-if="submissionError"
                        block
                        type="error"
                        :class="
                            !$vuetify.breakpoint.smAndDown
                                ? 'mt-2 mr-10'
                                : 'mt-2'
                        "
                    >
                        {{ submissionError || 'Please try again.' }}
                    </v-alert>

                    <v-alert
                        v-else-if="showValidationErrors"
                        block
                        type="error"
                        :class="
                            !$vuetify.breakpoint.smAndDown
                                ? 'mt-2 mr-10'
                                : 'mt-2'
                        "
                    >
                        Missing Required Fields!
                    </v-alert>
                    <v-card
                        v-else
                        flat
                        height="72"
                        :class="
                            !$vuetify.breakpoint.smAndDown
                                ? 'mt-2 mr-10'
                                : 'mt-2'
                        "
                    >
                    </v-card>
                </div>
            </div>
            <div class="mb-4">
                <div
                    class="justify-end"
                    :class="
                        $vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'
                    "
                >
                    <div>
                        <base-btn
                            :block="$vuetify.breakpoint.smAndDown"
                            :class="
                                !$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mt-1'
                            "
                            large
                            color="primary"
                            outlined
                            :disabled="postingRma"
                            @click="goBack"
                        >
                            Back
                        </base-btn>
                    </div>
                    <!-- <div>
                        <base-btn
                            :block="$vuetify.breakpoint.smAndDown"
                            :class="
                                !$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mt-1'
                            "
                            large
                            color="primary"
                            outlined
                            :disabled="submitted"
                            @click="$emit('cancel')"
                        >
                            Cancel
                        </base-btn>
                    </div> -->

                    <div @click="validateOnContinue">
                        <base-btn
                            data-cy="btn-continue-to-submit-rma"
                            :block="$vuetify.breakpoint.smAndDown"
                            :disabled="!formValid"
                            :class="
                                !$vuetify.breakpoint.smAndDown
                                    ? 'mr-10'
                                    : 'mt-1'
                            "
                            color="primary"
                            large
                            :loading="postingRma"
                            outlined
                            @click="submitFinal"
                        >
                            Submit Request to Recycle
                        </base-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-form>
</template>
<style scoped>
.field-values {
    font-weight: 500;
}
</style>
