<script>
import { dropOffLocations } from '@/api'
import PackagingLocations from './packaging_locations.vue'
import GusaContainer from '@/layouts/home/container.vue'
import { mdiCloseThick } from '@mdi/js'
import cloneDeep from 'lodash/cloneDeep'
import RepairTypeMixin from '@/mixins/repair_type'
import { isEmpty } from 'lodash'

export default {
    name: `PackingStep`,
    components: {
        PackagingLocations,
        GusaContainer
    },
    mixins: [RepairTypeMixin],
    props: {
        address: {
            type: Object,
            default: () => {}
        },
        international: {
            type: Boolean,
            required: false,
            default: false
        },
        canada: {
            type: Boolean,
            required: false,
            default: false
        },
        apo: {
            type: Boolean,
            required: false,
            default: false
        },
        quotePackingData: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data: () => ({
        selection: ``,
        selections: {
            label: {
                havePackaging: true,
                requestBox: false
            },
            qr: {
                havePackaging: false,
                requestBox: false
            },
            box: {
                havePackaging: false,
                requestBox: true
            },
            international: {
                havePackaging: false,
                requestBox: false
            }
        },
        mdiCloseThick,
        dropOffLocations: null,
        updateLocations: false
    }),
    computed: {
        userCanContinue() {
            return this.apo || this.international || this.advanceExchange || !!this.selection
        }
    },
    watch: {
        selection: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    if (!isEmpty(this.quotePackingData)) {
                        const keys = Object.keys(this.selections)
                        for (let i = 0; i < keys.length; i++) {
                            const option = JSON.stringify(this.selections[keys[i]])
                            const check = JSON.stringify(this.quotePackingData)
                            if (option === check) {
                                this.selection = keys[i]
                                break
                            }
                        }
                    }
                })
            }
        },
        address: {
            async handler(n, o) {
                await this.resetForm()
                await this.$nextTick()
                if (this.apo || this.canada) {
                    this.selection = `label`
                    this.emitInput()
                } else if (this.international) {
                    this.selection = `international`
                    this.emitInput()
                }
            },
            deep: true
        }
    },

    methods: {
        async emitInput() {
            if (this.selection === `qr`) {
                this.getDropOffLocations()
            }
            await this.$nextTick()
            this.$emit(`packingInput`, this.apo ? this.selections.label : this.selections[this.selection])
        },

        async resetForm() {
            await this.$nextTick()
            this.selection = ``
            this.updateLocations = true
        },
        getDropOffLocations: async function (value) {
            if (!this.apo && this.updateLocations) {
                try {
                    const result = await dropOffLocations(this.address)
                    if (result.status === 200) {
                        this.dropOffLocations = cloneDeep(result.data)
                    }
                    this.updateLocations = false
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }
}
</script>

<template>
    <gusa-container container-class="pt-0">
        <div style="display: flex; flex-direction: row">
            <img style="width: 130px; margin-bottom: 1%" src="../../../assets/logos/getac-logo.svg" />
            <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%">CREATE RMA</div>
        </div>
        <v-divider style="margin-bottom: 1%; border-color: gray" />
        <v-form ref="form">
            <v-row justify="start" align="start">
                <v-col cols="12" sm="6">
                    <base-info-card title="Packaging & Shipping" color="primary" />
                    <div v-if="!canada && !international && !advanceExchange">
                        <br />
                        <span>I have a box and packaging.</span>
                        <v-radio-group
                            id="packaging"
                            v-model="selection"
                            class="packaging-options"
                            :disabled="international || apo"
                            dense
                            column
                            @change="emitInput"
                        >
                            <v-radio
                                class="pl-2 pb-2"
                                label="Yes - Your printable shipping label will be immediately provided."
                                value="label"
                                data-cy="form-have-box"
                            />
                            <v-radio
                                class="pl-2 pb-2"
                                label="No - You will be provided a QR code that you will need to take to an authorized FedEx Retail Office location."
                                value="qr"
                                data-cy="form-need-box"
                            />
                            <v-radio
                                class="pl-2"
                                label="No - Request a box to be shipped (with label inside) to you for this RMA."
                                value="box"
                                data-cy="form-request-box"
                            />
                        </v-radio-group>
                    </div>
                    <v-expand-transition>
                        <div class="white--text">
                            <br />
                            <span v-if="selection === `qr`">
                                * You will be able to drop-off at an authorized FedEx Retail Office location, where
                                packaging and shipping services have been coordinated for you.
                            </span>
                            <span v-else-if="advanceExchange && !international">
                                * You will receive a box with the new device, a new label will be provided to you to
                                return your current one.
                            </span>
                            <span v-else-if="canada">
                                * Your shipping label will be generated and emailed to you after the RMA(s) have been
                                created.
                            </span>
                            <span v-else-if="international">
                                * Shipping labels and associated documents will not be provided automatically for out of
                                country RMA(s). Please reach out to Getac support for assistance after the RMA(s) have
                                been created, or if you are having issues creating a RMA.
                            </span>
                            <span v-else-if="selection === `box`">
                                * Please allow 3-5 business days for the box to be shipped to your location. The
                                shipping label will be included in your box.
                            </span>
                        </div>
                    </v-expand-transition>
                </v-col>
                <v-col cols="12" sm="6">
                    <!-- start office locations -->
                    <div v-if="selection === `qr` && !apo" class="text-center">
                        <v-dialog v-if="$vuetify.breakpoint.smAndDown">
                            <template v-slot:activator="{ on }">
                                <a v-on="on">View Nearby FedEx Office Locations</a>
                            </template>
                            <packaging-locations :locations="dropOffLocations" />
                        </v-dialog>
                        <div v-else>
                            <packaging-locations :locations="dropOffLocations" />
                        </div>
                    </div>
                    <!-- end office locations -->
                </v-col>
            </v-row>
            <v-row justify="end">
                <v-col>
                    <div class="justify-end" :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'">
                        <div>
                            <base-btn
                                :class="!$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mt-1'"
                                color="primary"
                                :block="$vuetify.breakpoint.smAndDown"
                                outlined
                                @click="$emit('back')"
                            >
                                Back
                            </base-btn>
                        </div>
                        <div>
                            <base-btn
                                :class="!$vuetify.breakpoint.smAndDown ? 'mr-10' : 'mt-1'"
                                :disabled="!userCanContinue"
                                color="primary"
                                outlined
                                :block="$vuetify.breakpoint.smAndDown"
                                data-cy="btn-continue-to-confirm"
                                @click="$emit('continue')"
                            >
                                Continue
                            </base-btn>
                        </div>
                        <br />
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </gusa-container>
</template>

<style>
.packaging-options .v-radio .v-label {
    color: white;
}
</style>
