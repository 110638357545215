export default {
    name: 'RepairTypeMixin',
    props: {
        repairType: {
            type: String,
            default: ``
        }
    },
    computed: {
        advanceExchange() {
            return this.repairType.includes('ADVANCE_EXCHANGE')
        }
    }
}
