<template>
    <div class="main-phone-div">
        <div :class="errorMessage ? 'phone-border-error' : 'phone-border'">
            <vue-tel-input
                data-cy="base-phone"
                v-bind="$attrs"
                @input="typing"
                @blur="checkValidation"
                v-on="$listeners"
            />
        </div>

        <div
            v-if="errorMessage"
            class="phone-input-empty-space v-text-field__details"
            ><div class="v-messages theme--light error--text" role="alert"
                ><div class="v-messages__wrapper"
                    ><div class="v-messages__message pt-1 pl-3">{{
                        errorMessage
                    }}</div></div
                ></div
            ></div
        >
    </div>
</template>

<script>
export default {
    name: `BaseTelInput`,

    inheritAttrs: false,

    data() {
        return {
            isValid: false,
            errorMessage: ``,
            userValue: ``
        }
    },

    methods: {
        typing(number, phoneObject) {
            this.$emit(`update`, [number, phoneObject])
            this.userValue = number
            this.errorMessage = ``
            this.isValid = phoneObject && phoneObject.valid
        },

        checkValidation(event) {
            if (
                this.$attrs[`input-options`] &&
                this.$attrs[`input-options`].required &&
                !this.userValue
            ) {
                this.errorMessage = `Required`
            } else if (this.userValue && !this.isValid) {
                this.errorMessage = `Invalid Format or Number`
            }
        }
    }
}
</script>

<style scoped lang="scss">
.main-phone-div {
    border-radius: 4px;
    height: 67px;
}

.vue-tel-input {
    border: unset !important;
    box-shadow: unset !important;
    font-size: 16px;
    height: 38px;
}

.phone-border {
    border: solid 1px rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    box-shadow: 0 0 0 1px transparent inset;
}
.phone-border:focus-within {
    border: solid 1px #db5120;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #db5120 inset;
}
.phone-border-error {
    border: solid 1px #ff5252;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #ff5252 inset;
}
</style>
