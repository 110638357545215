// Imports
import Vue from 'vue'
import Router from 'vue-router'
import lazyLoad from './lazy_load_view.js'
import store from '@/state/store'
import createIwRma from '@/views/create_rma/iw.vue'
import recycleUnit from '@/views/create_rma/recycle_unit.vue'
import recycleBattery from '@/views/create_rma/recycle_battery.vue'
import { authGuard } from '@/auth/authGuard.js'
import { getInstance } from '@/auth'

Vue.use(Router)

export const routes = [
    {
        path: `/`,
        component: () => lazyLoad(import(`@/layouts/home/index.vue`)),
        children: [
            {
                path: ``,
                name: `home`,
                component: () => lazyLoad(import(`@/views/home/index.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: `create-rma/:serial?`,
                name: `create-rma`,
                component: createIwRma,
                props: true,
                meta: {
                    async beforeResolve(to, from, next) {
                        try {
                            await store.dispatch(`location/cacheCountries`)
                            next()
                        } catch (exception) {
                            next(exception)
                        }
                    }
                }
            },
            {
                path: 'create-rma/:serial?/confirm-quote',
                name: 'rma-confirm-quote',
                component: () => lazyLoad(import(`@/views/create_rma/confirm_quote.vue`)),
                meta: {
                    isPublic: true
                },
                // beforeEnter: (to, from, next) => {
                //     const isRedirected = from.name === 'create-rma'
                //     if (isRedirected) {
                //         next()
                //     } else {
                //         next({ name: 'create-rma' })
                //     }
                // },
                props: true
            },
            {
                path: 'parts-order',
                name: 'parts-order',
                component: () => lazyLoad(import(`@/views/parts_order/index.vue`)),
                props: true,
                meta: {
                    isPublic: true
                }
            },
            {
                path: 'parts-order/manual',
                name: 'parts-order-manual',
                component: () => lazyLoad(import(`@/views/parts_order/manual.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: 'video-parts-order',
                name: 'video-parts-order',
                component: () => lazyLoad(import(`@/views/video_parts_order/index.vue`)),
                props: true,
                meta: {
                    isPublic: true
                }
            },
            {
                path: `/canceled`,
                name: `rma-canceled`,
                component: () => lazyLoad(import(`@/views/create_rma/canceled.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: `/success`,
                name: `rma-completed`,
                component: () => lazyLoad(import(`@/views/create_rma/success.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: 'parts-order/cart',
                name: 'parts-order-cart',
                component: () => lazyLoad(import(`@/views/parts_order_cart/index.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: 'parts-order/checkout',
                name: 'parts-order-checkout',
                component: () => lazyLoad(import(`@/views/parts_order_checkout/index.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: 'parts-order/success',
                name: 'parts-order-success',
                component: () => lazyLoad(import(`@/views/parts_order/order_success.vue`)),
                meta: {
                    isPublic: true
                },
                beforeEnter: (to, from, next) => {
                    const isRedirected = from.name === 'parts-order-checkout'
                    if (isRedirected) {
                        next()
                    } else {
                        next({ name: 'parts-order' })
                    }
                },
                props: true
            },
            {
                path: 'pay-quote/:quoteid?/:reference',
                name: 'pay-quote',
                component: () => lazyLoad(import(`@/views/quote_payments/pay_quote.vue`)),
                meta: {
                    // isPublic: true
                },
                props: true
            },
            {
                path: 'service-quote-successful',
                name: 'service-quote-success',
                component: () => lazyLoad(import(`@/views/quote_payments/payment_success.vue`)),
                meta: {
                    isPublic: true
                },
                props: true
            },
            {
                path: 'service-quote-cancel',
                name: 'service-quote-canceled',
                component: () => lazyLoad(import(`@/views/quote_payments/payment_canceled.vue`)),
                meta: {
                    isPublic: true
                },
                props: true
            },
            {
                path: 'parts-usage',
                name: 'parts-usage',
                component: () => lazyLoad(import(`@/views/parts_usage/index.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: 'upgrade-device/:serial?',
                name: 'upgrade-device',
                props: true,
                component: () => lazyLoad(import(`@/views/upgrade_device/index.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: 'upgrade-device/:serial?/confirm-quote',
                name: 'upgrade-confirm-quote',
                component: () => lazyLoad(import(`@/views/upgrade_device/confirm_quote.vue`)),
                meta: {
                    isPublic: true
                },
                props: true
            },
            {
                path: `recycle-unit/:serial?`,
                name: `recycle-unit`,
                component: recycleUnit,
                props: true,
                meta: {
                    async beforeResolve(to, from, next) {
                        try {
                            await store.dispatch(`location/cacheCountries`)
                            next()
                        } catch (exception) {
                            next(exception)
                        }
                    }
                }
            },
            {
                path: 'recycle-battery',
                name: 'recycle-battery',
                component: recycleBattery,
                props: true
            },
            {
                path: `help-support`,
                name: `help-support`,
                component: () => lazyLoad(import(`@/views/help_support/index.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: `help-support/complete`,
                name: `help-support-submitted`,
                component: () => lazyLoad(import(`@/views/help_support/complete.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: `your-account`,
                name: `your-account`,
                redirect: `your-account/rma-history`
            },
            {
                path: `auth-help-and-support`,
                name: `auth-help-and-support`,
                component: () => lazyLoad(import(`@/views/auth/auth_help_and_support`))
            },
            {
                path: `your-account/rma-history`,
                name: `rma-history`,
                component: () => lazyLoad(import(`@/views/user_account/rma_history`))
            },
            {
                path: `your-account/branches`,
                name: `branches`,
                component: () => lazyLoad(import(`@/views/user_account/branches`)),
                beforeEnter: (to, from, next) => {
                    const auth = getInstance()
                    return auth.userInOrganization() ? next() : next({ name: 'home' })
                }
            },
            {
                path: `your-account/parts-orders-history`,
                name: `parts-orders-history`,
                component: () => lazyLoad(import(`@/views/user_account/parts_order_history`))
            },
            {
                path: `your-account/product-registration`,
                name: `product-registration`,
                component: () => lazyLoad(import(`@/views/user_account/product_registration`))
            },
            {
                path: `your-account/drivers`,
                name: `drivers`,
                component: () => lazyLoad(import(`@/views/user_account/drivers`))
            },
            {
                path: `your-account/account-settings`,
                name: `account-settings`,
                component: () => lazyLoad(import(`@/views/user_account/account_settings`)),
                meta: {
                    async beforeResolve(to, from, next) {
                        try {
                            await store.dispatch(`location/cacheCountries`)
                            next()
                        } catch (exception) {
                            next(exception)
                        }
                    }
                }
            },
            {
                path: `your-account/quotes`,
                name: `quotes`,
                component: () => lazyLoad(import(`@/views/user_account/quotes`))
            },
            {
                path: `your-account/dashboard`,
                name: `dashboard`,
                component: () => lazyLoad(import(`@/views/user_account/dashboard`))
            },
            {
                path: `your-account/saved-quotes`,
                name: `quotes`,
                component: () => lazyLoad(import(`@/views/user_account/quotes`))
            },
            // deprecate after launching new auth client
            {
                path: `oidc-callback`, // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
                name: `oidc-callback`,
                component: () => lazyLoad(import(`@/views/auth/oidc_callback.vue`))
            },
            // deprecate after launching new auth client
            {
                path: `sign-out`, // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
                name: `sign-out`,
                component: () => lazyLoad(import(`@/views/auth/sign_out.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: `unauthorized`,
                name: `unauthorized`,
                component: () => lazyLoad(import(`@/views/auth/unauthorized.vue`)),
                meta: {
                    isPublic: true
                }
            },
            {
                path: `*`,
                name: `four-oh-four`,
                component: () => lazyLoad(import(`./views/_404`)),
                meta: {
                    isPublic: true
                }
            }
        ]
    }
]

const router = new Router({
    mode: `history`,
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash }
        if (savedPosition) return savedPosition
        return { x: 0, y: 0 }
    },
    routes
})

router.beforeEach(authGuard)

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...

                            // Complete the redirect.
                            next(...args)
                            reject(new Error(`Redirected`))
                        } else {
                            resolve()
                        }
                    })
                } else {
                    // Otherwise, continue resolving the route.
                    resolve()
                }
            })
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return
    }

    // If we reach this point, continue resolving the route.
    next()
})

export default router
