<script>
import ValidateWarranty from '@/components/validate_warranty/validate_warranty.vue'
import UnitCard from '@/components/unit_card/unit_card.vue'
import GusaContainer from '@/layouts/home/container.vue'
import FileUploader from '@/components/file_uploader/file_uploader.vue'
import ProblemForm from '../forms/problem_form.vue'

export default {
    name: `ProblemStep`,
    components: {
        ValidateWarranty,
        UnitCard,
        FileUploader,
        ProblemForm,
        GusaContainer
    },
    props: {
        value: { type: Object, default: () => {} },
        userEmail: { type: String, required: true },
        serial: { type: String, default: `` },
        repairType: { type: String, default: `` },
        productType: { type: String, default: `` },
        rmaWarningMessage: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            serialIsValid: false,
            problemIsValid: false,
            showErrors: false,
            unitData: {}
        }
    },

    computed: {
        userCanCreateOnSerial() {
            return this.serialIsValid
        },
        formValid() {
            return this.userCanCreateOnSerial && this.problemIsValid
        },
        isSpecialModel() {
            return this.unitData.model === 'EX80'
        }
    },
    methods: {
        emitAttachments(v = []) {
            const a = v.map((e) => e.bucketFileName)
            this.$emit(`attachmentsInput`, a)
        },
        resetForm() {
            this.$refs.problemForm.resetForm()
            this.$refs.fileUploader.reset()
        },
        validateOnContinue() {
            this.$refs.problemForm.$children[0].validate()
            if (!this.formValid) this.showErrors = true
            const self = this
            setTimeout(() => {
                self.showErrors = false
            }, 4000)
        }
    }
}
</script>

<template>
    <gusa-container container-class="pt-0">
        <div style="display: flex; flex-direction: row">
            <img
                style="width: 130px; margin-bottom: 1%"
                src="../../../assets/logos/getac-logo.svg"
            />
            <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%"
                >CREATE RMA</div
            >
        </div>
        <v-divider style="margin-bottom: 1%; border-color: gray" />
        <v-alert
            v-if="rmaWarningMessage"
            border="left"
            color="blue-grey"
            dark
            dense
            class="mt-5"
            type="info"
        >
            <!--Parts Order will not be availabe between Dec 1 and 8 Dec because of
            year end inventory reconciliation -->
            {{ rmaWarningMessage }}
        </v-alert>
        <problem-form
            ref="problemForm"
            col-md="6"
            :pn="unitData.pn"
            :model="unitData.model"
            :serial-is-valid="userCanCreateOnSerial"
            :in-warranty="unitData.iw"
            :allow-oow="unitData.allowOow"
            :repair-type="unitData.allowedRepairType"
            :product-type="productType"
            :serial="unitData.serial"
            @form-is-valid="problemIsValid = $event"
            @problemInput="$emit('problemInput', $event)"
        >
            <template v-slot:serial-validation>
                <v-alert
                    v-if="isSpecialModel"
                    border="left"
                    color="blue-grey"
                    dark
                    dense
                    class="mt-5"
                    type="info"
                >
                    Please contact Getac helpdesk at 866-394-3822 or send email
                    to GetacSupport_US@getac.com to arrange the return of your
                    device.
                </v-alert>
                <v-card
                    v-if="!unitData.serial"
                    min-height="168"
                    flat
                    color="grey"
                    class="mb-5"
                />

                <unit-card
                    v-else
                    min-height="168"
                    :warranty-type="unitData.warrantyType"
                    :serial="unitData.serial"
                    :model="unitData.model"
                    :model-family="unitData.modelFamily"
                    :iw="unitData.iw"
                    :allow-oow="unitData.allowOow"
                    :rma-exists="unitData.rmaExists"
                    :repair-type="unitData.allowedRepairType"
                    :end-device-warranty="unitData.endDeviceWarranty"
                    :is-e-o-s="unitData.isEOS"
                    :is-special-model="isSpecialModel"
                    class="mb-5"
                />

                <validate-warranty
                    max-width="250"
                    :outside-serial="serial"
                    @serialData="
                        ;(unitData = $event), $emit('unitInput', $event)
                    "
                    @isValid="serialIsValid = $event"
                />
            </template>
            <template v-slot:file-uploader>
                <file-uploader
                    ref="fileUploader"
                    :user-email="userEmail"
                    :btn-disabled="!userCanCreateOnSerial"
                    v-on="$listeners"
                    @fileNames="emitAttachments"
                />
            </template>
        </problem-form>
        <div>
            <div
                class="fields-missing justify-end"
                :class="
                    $vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'
                "
            >
                <v-alert
                    v-if="showErrors"
                    block
                    type="error"
                    :class="
                        !$vuetify.breakpoint.smAndDown ? 'mt-2 mr-10' : 'mt-2'
                    "
                >
                    Missing Required Fields!
                </v-alert>
                <v-card
                    v-else
                    flat
                    height="72"
                    :class="
                        !$vuetify.breakpoint.smAndDown ? 'mt-2 mr-10' : 'mt-0'
                    "
                >
                </v-card>
            </div>
        </div>
        <div class="mb-4">
            <div
                class="justify-end fields-missing"
                :class="
                    $vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'
                "
            >
                <div @click="validateOnContinue">
                    <base-btn
                        data-cy="btn-continue-to-contact"
                        :disabled="!formValid"
                        :block="$vuetify.breakpoint.smAndDown"
                        color="primary"
                        :class="
                            !$vuetify.breakpoint.smAndDown
                                ? 'mt-0 mr-10'
                                : 'mt-0'
                        "
                        outlined
                        @click="$emit('continue')"
                    >
                        Continue
                    </base-btn>
                </div>
            </div>
        </div>
    </gusa-container>
</template>
