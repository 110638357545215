<template>
    <component :is="tag" v-bind="$attrs" v-on="$listeners">
        <div :class="classes" class="d-flex">
            <span class="pt-1" :class="dark ? 'white--text' : ''">{{ titleSentence }}</span>
            <span v-if="icon" class="align-self-center">
                <v-icon :size="iconSize" color="accent">
                    {{ mdiArrowRight }}
                </v-icon>
            </span>
        </div>
    </component>
</template>

<script>
import { mdiArrowRight } from '@mdi/js'
export default {
    name: `BaseHeading`,

    inject: {
        theme: {
            default: () => ({ isDark: false })
        },
        heading: {
            default: () => ({ align: `left` })
        }
    },

    provide() {
        return {
            heading: {
                align: this.align
            }
        }
    },

    props: {
        dark: {
            type: Boolean,
            required: false
        },
        icon: Boolean,
        align: {
            type: String,
            default() {
                return this.heading.align
            }
        },
        size: {
            type: String,
            default: `display-2`
        },
        space: {
            type: [Number, String],
            default: 4
        },
        mobileSize: {
            type: String,
            default: `display-1`
        },
        mobileBreakPoint: {
            type: [Number, String],
            default: 768
        },
        tag: {
            type: String,
            default: `h1`
        },
        title: { type: String, required: true },
        weight: {
            type: String,
            default: `black`
        }
    },
    data: () => ({
        mdiArrowRight
    }),

    computed: {
        titleSentence() {
            return this.title
        },
        classes() {
            const classes = [
                this.fontSize,
                `font-weight-${this.weight}`,
                `mb-${this.space}`,
                `text-${this.align}`,
                this.theme.isDark && `white--text`
            ]
            return classes
        },
        iconSize() {
            return this.$vuetify.breakpoint.width >= this.mobileBreakPoint
                ? `46`
                : `33`
        },
        fontSize() {
            return this.$vuetify.breakpoint.width >= this.mobileBreakPoint
                ? this.size
                : this.mobileSize
        }
    }
}
</script>
<style lang="css">
.no-wrap {
    white-space: nowrap;
}
</style>
