<script>
import formRules from '@/utils/form_rules'

export default {
    name: `ContactForm`,
    inheritAttrs: false,
    props: {
        denseGrid: Boolean,
        userStoredContact: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            formRules,
            phone: {},
            phoneInput: ``,
            phoneValid: false,
            formValid: false,
            formInput: {
                phone: ``,
                phoneCountryCode: ``
            }
        }
    },
    mounted() {
        this.copyFromStore()
    },
    methods: {
        copyFromStore() {
            this.userStoredContact.firstName && (this.formInput.firstName = this.userStoredContact.firstName)
            this.userStoredContact.lastName && (this.formInput.lastName = this.userStoredContact.lastName)
            this.userStoredContact.email && (this.formInput.email = this.userStoredContact.email)
            this.userStoredContact.company && (this.formInput.company = this.userStoredContact.company)
            this.userStoredContact.phone && (this.phoneInput = this.userStoredContact.phone)
            this.emitValidation()
            this.emitInput()
        },
        assignPhone(a) {
            // eslint-disable-next-line no-unused-vars
            const [input, phoneObject] = a
            this.phoneValid = phoneObject.valid
            this.formInput.phone = phoneObject.number
            this.formInput.phoneCountryCode = phoneObject.countryCode
            this.emitValidation()
            this.emitInput().then()
        },
        async resetForm() {
            this.$refs.form.reset()
            this.phoneInput = ``
            await this.$nextTick()
            this.copyFromStore()
        },
        async emitValidation() {
            this.$emit(`form-is-valid`, this.formValid && this.phoneValid)
        },
        async emitInput() {
            await this.$nextTick()
            this.emitValidation()
            this.$emit(`contactInput`, this.formInput)
        }
    }
}
</script>

<template>
    <v-form ref="form" v-model="formValid" @input="emitValidation">
        <v-row :dense-grid="denseGrid">
            <v-col :cols="$vuetify.breakpoint.width <= 400 ? '12' : ''" class="pb-1 pt-11103">
                <base-text-field
                    id="firstName"
                    v-model="formInput.firstName"
                    data-cy="form-contact-first-name"
                    label="First Name"
                    v-bind="$attrs"
                    :rules="[formRules.required, formRules.maxLength(35, 'characters')]"
                    @input="emitInput"
                />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.width <= 400 ? '12' : ''" class="pb-1 pt-3">
                <base-text-field
                    id="lastName"
                    v-model="formInput.lastName"
                    data-cy="form-contact-last-name"
                    v-bind="$attrs"
                    label="Last Name"
                    :rules="[formRules.required, formRules.maxLength(35, 'characters')]"
                    @input="emitInput"
                />
            </v-col>
        </v-row>
        <v-row :dense-grid="denseGrid">
            <v-col :cols="$vuetify.breakpoint.width <= 400 ? '12' : ''" class="py-1">
                <base-text-field
                    id="email"
                    v-model="formInput.email"
                    data-cy="form-contact-email"
                    v-bind="$attrs"
                    label="E-Mail"
                    :rules="[formRules.required, formRules.email]"
                    @input="emitInput"
                />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.width <= 400 ? '12' : ''" class="py-1 phone-input">
                <base-tel-input
                    id="phone"
                    ref="phone"
                    v-model="phoneInput"
                    class="tele-input"
                    valid-characters-only
                    :dropdown-options="{
                        showDialCodeInSelection: true,
                        showFlags: true,
                        showDialCodeInList: true,
                        tabindex: 0
                    }"
                    :default-country="'US'"
                    :input-options="{
                        placeholder: 'Phone',
                        maxlength: 25,
                        autocomplete: 'on',
                        name: 'telephone',
                        required: true,
                        autofocus: false,
                        id: '',
                        readonly: false,
                        tabindex: 0,
                        type: 'tel',
                        styleClasses: ''
                    }"
                    @update="assignPhone"
                />
            </v-col>
        </v-row>
        <v-row :dense-grid="denseGrid">
            <v-col :cols="$vuetify.breakpoint.width <= 400 ? '12' : ''" class="py-1">
                <base-text-field
                    id="company"
                    v-model="formInput.company"
                    data-cy="form-contact-company-name"
                    v-bind="$attrs"
                    label="Company Name"
                    :rules="[formRules.maxLength(35, 'characters')]"
                    @input="emitInput"
                />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.width <= 400 ? '12' : ''" class="py-1">
                <base-text-field
                    id="position"
                    v-model="formInput.position"
                    data-cy="form-contact-position"
                    v-bind="$attrs"
                    label="Position"
                    :rules="[formRules.maxLength(35, 'characters')]"
                    @input="emitInput"
                />
            </v-col>
        </v-row>
        <!-- <base-btn small outlined color="accent" @click="copyFromStore"
            >Import from Account</base-btn
        > -->
    </v-form>
</template>
