<script>
import formRules from '@/utils/form_rules'

export default {
    name: `AddressForm`,
    inheritAttrs: false,
    props: {
        denseGrid: Boolean,

        apo: Boolean,

        cities: {
            type: Array,
            default: () => []
        },

        statesLoading: Boolean,
        citiesLoading: Boolean,

        stateProvinces: {
            type: Array,
            default: () => []
        },

        countries: {
            type: Array,
            default: () => []
        },

        userStoredAddress: {
            type: Object,
            default: () => {}
        },

        allowPO: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            formRules,
            phone: null,
            formValid: false,
            formInput: {
                country: ``,
                state: ``
            }
        }
    },

    computed: {
        addressLine1Rules() {
            const rules = [formRules.required, formRules.maxLength(35, 'characters')]
            if (!this.allowPO) {
                rules.push(formRules.poBox)
            }
            return rules
        },
        addressLine2Rules() {
            const rules = [formRules.maxLength(35, 'characters')]
            if (!this.allowPO) {
                rules.push(formRules.poBox)
            }
            return rules
        }
    },

    mounted() {
        this.copyFromStore()
    },

    methods: {
        async copyFromStore() {
            this.formInput.country = 'US'
            await this.updateCountry()
            if (this.userStoredAddress.country) {
                this.formInput.country = this.userStoredAddress.country
                await this.updateCountry()
            }
            this.userStoredAddress.state && (this.formInput.state = this.userStoredAddress.state)
            this.userStoredAddress.city && (this.formInput.city = this.userStoredAddress.city)
            this.userStoredAddress.line1 && (this.formInput.line1 = this.userStoredAddress.line1)
            this.userStoredAddress.line2 && (this.formInput.line2 = this.userStoredAddress.line2)
            this.userStoredAddress.postalCode && (this.formInput.postalCode = this.userStoredAddress.postalCode)
            this.emitValidation()
            this.emitInput()
        },
        async resetForm() {
            this.$refs.form.reset()
            await this.$nextTick()
            this.copyFromStore()
        },
        emitValidation(v) {
            this.$emit(`form-is-valid`, v)
        },
        async emitInput() {
            await this.$nextTick()
            this.$emit(`addressInput`, this.formInput)
        },
        async updateCountry(reset = true) {
            this.$emit(`countryUpdated`, this.formInput.country)
            if (reset) this.formInput.state = ``
            await this.emitInput()
        },
        async updateState() {
            this.$emit(`stateUpdated`, [this.formInput.country, this.formInput.state])
            if (
                ([`AA`, `AE`, `AP`].includes(this.formInput.state) &&
                    ![`APO`, `FPO`, `DPO`].includes(this.formInput.city)) ||
                (![`AA`, `AE`, `AP`].includes(this.formInput.state) &&
                    [`APO`, `FPO`, `DPO`].includes(this.formInput.city))
            )
                this.formInput.city = ``
            this.emitInput()
        }
    }
}
</script>

<template>
    <v-form ref="form" v-model="formValid" @input="emitValidation">
        <v-row :dense-grid="denseGrid">
            <v-col class="pb-1 pt-3">
                <base-text-field
                    id="line1"
                    v-model="formInput.line1"
                    data-cy="form-address1"
                    v-bind="$attrs"
                    label="Street Address"
                    clearable
                    :rules="addressLine1Rules"
                    maxlength="35"
                    @input="emitInput"
                />
            </v-col>
        </v-row>
        <v-row :dense-grid="denseGrid">
            <v-col class="py-1">
                <base-text-field
                    id="line2"
                    v-model="formInput.line2"
                    data-cy="form-address2"
                    v-bind="$attrs"
                    clearable
                    label="Apartment, suite, unit, building, floor, etc."
                    :rules="addressLine2Rules"
                    maxlength="35"
                    @input="emitInput"
                />
            </v-col>
        </v-row>
        <v-row :dense-grid="denseGrid">
            <v-col :cols="$vuetify.breakpoint.width <= 400 ? '12' : ''" class="py-1">
                <base-autocomplete
                    id="country"
                    v-model="formInput.country"
                    data-cy="form-address-country"
                    v-bind="$attrs"
                    item-text="name"
                    item-value="countryCode"
                    :items="countries"
                    label="Country"
                    clearable
                    :rules="[formRules.required, formRules.maxLength(35, 'characters')]"
                    placeholder="Country"
                    @input="emitInput"
                    @change="updateCountry"
                />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.width <= 400 ? '12' : ''" class="py-1">
                <base-autocomplete
                    id="state"
                    v-model="formInput.state"
                    :loading="statesLoading"
                    data-cy="form-address-state"
                    v-bind="$attrs"
                    :disabled="statesLoading || !formInput.country"
                    item-text="name"
                    item-value="stateCode"
                    clearable
                    :items="stateProvinces"
                    label="State/Province/Region"
                    :rules="
                        formInput.country !== 'US' ? [] : [formRules.required, formRules.maxLength(35, 'characters')]
                    "
                    placeholder="State"
                    @input="emitInput"
                    @change="updateState"
                />
            </v-col>
        </v-row>
        <v-row :dense-grid="denseGrid">
            <v-col :cols="$vuetify.breakpoint.width <= 400 ? '12' : ''" class="py-1">
                <base-combobox
                    v-if="apo"
                    id="city-apo"
                    v-model="formInput.city"
                    class="no-arrow"
                    data-cy="form-address-city"
                    v-bind="$attrs"
                    label="City"
                    :loading="citiesLoading"
                    :disabled="citiesLoading"
                    clearable
                    :rules="[formRules.required, formRules.maxLength(35, 'characters')]"
                    item-text="name"
                    item-value="name"
                    :items="cities"
                    :return-object="false"
                    @input="emitInput"
                />
                <base-text-field
                    v-else
                    id="city"
                    v-model="formInput.city"
                    clearable
                    data-cy="form-address-city"
                    v-bind="$attrs"
                    label="City"
                    :rules="[formRules.required, formRules.maxLength(35, 'characters')]"
                    @input="emitInput"
                />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.width <= 400 ? '12' : ''" class="py-1">
                <base-text-field
                    id="postalCode"
                    v-model="formInput.postalCode"
                    clearable
                    data-cy="form-address-zip"
                    v-bind="$attrs"
                    label="Postal Code"
                    :rules="[formRules.required, formRules.maxLength(12, 'characters')]"
                    @input="emitInput"
                />
            </v-col>
        </v-row>
    </v-form>
</template>
