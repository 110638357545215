function required(value) {
    return (
        (typeof value === 'string' ? !!value && !!value.trim() : value) ||
        `Required`
    )
}

function requiredBoolean(value) {
    return typeof value === 'boolean' || `Required`
}

function requiredEqLength(length, type = ``, visibleLength) {
    return function (value) {
        if (length)
            return (
                (!!value && value.length === length) ||
                `Must be ${visibleLength || length} ${type}`
            )
    }
}

function maxLength(length, type = ``) {
    return function (value) {
        if (length) {
            return (
                value === null ||
                value === undefined ||
                value.length <= length ||
                `Must be less than ${length + 1} ${type}`
            )
        }
    }
}

function minLength(length, type = ``) {
    return function (value) {
        if (length) {
            return (
                (!!value && value.length >= length) ||
                `Must be at least ${length} ${type}`
            )
        }
    }
}

function email(value) {
    return (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
        ) || `Must be a valid e-mail address`
    )
}

function poBox(value) {
    return (
        !/(?:P(?:ost(?:al)?)?[.\-\s]*(?:(?:O(?:ffice)?[.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i.test(
            value
        ) || `Cannot be a PO box`
    )
}

export default {
    required,
    requiredBoolean,
    email,
    requiredEqLength,
    maxLength,
    minLength,
    poBox
}
