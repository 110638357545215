import LogRocket from 'logrocket'
import { getInstance } from '@/auth'

const logRocketOptions = {
    network: {
        requestSanitizer: (request) => {
            // scrub senstive info from request
            let body
            try {
                body = request.body && JSON.parse(request.body)
            } catch {
                // do nothing
            }

            if (body && (body.newPassword || body.password)) {
                body.newPassword = `secret`
                body.password = `secret`
            }

            if (body && body.rmaInput && body.rmaInput.permissions) {
                body.rmaInput.permissions.userPassword = ``
                body.rmaInput.permissions.biosPassword = ``
            }

            if (body) {
                request.body = body
            }

            // scrub header value from request
            if (request.headers.Authorization) {
                request.headers.Authorization = `secret`
            }
            if (request.headers.authorization) {
                request.headers.authorization = `secret`
            }

            return request
        }
    }
}

if (process.env.NODE_ENV === `production`) {
    if (process.env.VUE_APP_IS_UAT) {
        LogRocket.init(`o7ai4p/uat-t7qhx`, logRocketOptions)
    } else {
        LogRocket.init(`o7ai4p/gsp-prod`, logRocketOptions)
    }

    const authInstance = getInstance()

    if (
        authInstance &&
        authInstance.isAuthenticated &&
        authInstance.user &&
        authInstance.user.sub
    ) {
        LogRocket.identify(authInstance.user.sub, authInstance.user)
    }
} else {
    // local-test og rocket project
    // LogRocket.init(`o7ai4p/local-test-6bjcr`, logRocketOptions)
}
