<template>
    <v-alert border="left" type="error" :rounded="false">
        {{ warningText }}
    </v-alert>
</template>

<script>
export default {
    name: `OowRmaFlag`,
    data: () => ({
        acknowledged: false,
        warningText: `Warning: you are currently recycling this unit. It will not be repaired/returned`
    })
}
</script>
