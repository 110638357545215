<template>
    <v-btn
        :color="color"
        :min-width="minWidth"
        class="nav-text font-weight-bold"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot />
    </v-btn>
</template>

<script>
export default {
    name: `BaseBtn`,

    props: {
        color: {
            type: String,
            default: `primary`
        },
        minWidth: {
            type: [Number, String],
            default: 164
        }
    }
}
</script>
