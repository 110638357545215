<template>
    <div id="paypal-button-container" :class="buttonClass" />
</template>

<script>
import { loadScript } from '@paypal/paypal-js'

export default {
    name: 'PaypalButton',
    props: {
        valid: {
            type: Boolean,
            required: true
        },
        create: {
            type: Function,
            required: true
        },
        approve: {
            type: Function,
            required: true
        },
        validate: {
            type: Function,
            required: true
        }
    },
    computed: {
        buttonClass() {
            return this.valid ? '' : 'paypal-button-disabled'
        }
    },
    mounted() {
        loadScript({
            enableFunding: ['card', 'credit'],
            disableFunding: ['paylater'],
            clientId: process.env.VUE_APP_PAYPAL_CLIENT
        }).then((paypal) => {
            paypal
                .Buttons({
                    style: {
                        layout: 'horizontal',
                        label: 'checkout',
                        shape: 'rect',
                        tagline: false,
                        height: 38
                    },
                    onInit: this.onInit,
                    onClick: this.onClick,
                    createOrder: this.createOrder,
                    onApprove: this.onApprove,
                    onError: this.onError,
                    onCancel: this.onCancel
                })
                .render('#paypal-button-container')
        })
    },
    methods: {
        onInit(_, actions) {
            actions.disable()
            this.$watch(
                'valid',
                (value) => {
                    if (value) actions.enable()
                    else actions.disable()
                },
                { immediate: true }
            )
        },
        onClick(_, actions) {
            this.$emit('click')
            if (!this.valid) {
                console.log('Invalid form, cannot continue with checkout process')
            } else {
                if (this.validate()) {
                    return actions.resolve()
                } else {
                    return actions.reject('PayPal redirect could not be completed due to incomplete/bad form data')
                }
            }
        },
        createOrder() {
            return this.create()
        },
        onApprove() {
            this.approve()
        },
        onError(e) {
            console.error(e)
        },
        onCancel() {}
    }
}
</script>

<style scoped>
.paypal-button-disabled {
    pointer-events: none;
}
</style>
