import { getCountries, getStates, getCities } from '@/api'
import vue from 'vue'

function dotNotation(x, y) {
    return `${x}.${y}`
}

export const state = {
    cachedCountries: [],
    cachedStates: {},
    cachedCities: {}
}

export const mutations = {
    CACHE_COUNTRIES(state, countries) {
        state.cachedCountries = countries
    },
    CACHE_STATES(state, { countryCode, states }) {
        vue.set(state.cachedStates, countryCode, states)
    },
    CACHE_CITIES(state, { countryCode, stateCode, cities }) {
        vue.set(state.cachedCities, dotNotation(countryCode, stateCode), cities)
    }
}

export const getters = {
    getCachedCountries: (state) => {
        return state.cachedCountries
    },
    getCachedStates:
        (state) =>
        ({ countryCode }) => {
            if (
                !state.cachedStates[countryCode] ||
                (state.cachedStates[countryCode] &&
                    !state.cachedStates[countryCode].length)
            )
                return []
            else return state.cachedStates[countryCode]
        },
    getCachedCities:
        (state) =>
        ({ countryCode, stateCode }) => {
            return state.cachedCities[dotNotation(countryCode, stateCode)] || []
        }
}

export const actions = {
    async init({ dispatch }) {
        await dispatch(`cacheCountries`)
    },
    async cacheCountries({ commit, state }) {
        try {
            if (!state.cachedCountries.length) {
                const { data } = await getCountries()
                commit(`CACHE_COUNTRIES`, data)
            }
        } catch {
            console.error(`Unable to fetch countries`)
        }
    },
    async cacheStates({ commit, state }, { countryCode = `` }) {
        try {
            if (
                state.cachedStates[countryCode] &&
                state.cachedStates[countryCode].length
            ) {
                return state.cachedStates[countryCode]
            } else {
                const { geonameId } =
                    state.cachedCountries.find(
                        (e) => e.countryCode === countryCode
                    ) || {}
                const { data } = await getStates({ geonameId })

                if (data && data.length) {
                    commit(`CACHE_STATES`, { countryCode, states: data })
                }
            }
        } catch (e) {
            console.error(`Unable to fetch states`)
        }
    },
    async cacheCities({ commit, state }, { countryCode, stateCode }) {
        try {
            const cityPath = dotNotation(countryCode, stateCode)
            if (state.cachedCities[cityPath]) {
                return state.cachedCities[cityPath]
            } else {
                const { geonameId, apo } =
                    state.cachedStates[countryCode].find(
                        (e) => e.stateCode === stateCode
                    ) || {}

                const { data } = await getCities({ geonameId, apo })

                if (data && data.length) {
                    commit(`CACHE_CITIES`, {
                        countryCode,
                        stateCode,
                        cities: data
                    })
                } else {
                    commit(`CACHE_CITIES`, {
                        countryCode,
                        stateCode,
                        cities: []
                    })
                }
            }
        } catch (e) {
            console.error(`Unable to fetch cities`)
        }
    }
}
