import { truncate } from '@/utils/truncate'
import { get } from 'lodash'

export function discountCalculation(subtotalField) {
    return {
        computed: {
            privateSubtotalField() {
                return truncate(get(this, subtotalField))
            },
            flatDiscountPercentage() {
                return this.$auth.getFlatDiscountPercentage()
            },
            userHasDiscountAvailable() {
                return !!this.flatDiscountPercentage
            },
            totalDiscountAmount() {
                return Math.min(
                    truncate(
                        this.privateSubtotalField *
                            (this.flatDiscountPercentage || 0)
                    ),
                    this.privateSubtotalField
                )
            },
            subtotalAfterDiscount() {
                return this.privateSubtotalField - this.totalDiscountAmount
            }
        }
    }
}
