import { deepMerge } from 'lodash'

export const relayStylePagination = (keyArgs) => {
    const assign = Object.assign

    if (keyArgs === undefined) {
        keyArgs = false
    }
    return {
        keyArgs,
        read: function (existing, _a) {
            const canRead = _a.canRead
            const readField = _a.readField
            if (!existing) return
            const edges = []
            let startCursor = ``
            let endCursor = ``
            existing.edges.forEach(function (edge) {
                if (canRead(readField(`node`, edge))) {
                    edges.push(edge)
                    if (edge.cursor) {
                        startCursor = startCursor || edge.cursor
                        endCursor = edge.cursor
                    }
                }
            })
            return assign(assign({}, getExtras(existing)), {
                edges: edges,
                pageInfo: assign(assign({}, existing.pageInfo), {
                    startCursor: startCursor,
                    endCursor: endCursor
                })
            })
        },
        merge: function (existing, incoming, _a) {
            if (existing === undefined) {
                existing = makeEmptyData()
            }
            const args = _a.args
            const isReference = _a.isReference
            const readField = _a.readField
            const incomingEdges = incoming.edges
                ? incoming.edges.map(function (edge) {
                      if (isReference((edge = assign({}, edge)))) {
                          edge.cursor = readField(`cursor`, edge)
                      }
                      return edge
                  })
                : []
            if (incoming.pageInfo) {
                const pageInfo1 = incoming.pageInfo
                const startCursor = pageInfo1.startCursor
                const endCursor = pageInfo1.endCursor
                const firstEdge = incomingEdges[0]
                const lastEdge = incomingEdges[incomingEdges.length - 1]
                if (firstEdge && startCursor) {
                    firstEdge.cursor = startCursor
                }
                if (lastEdge && endCursor) {
                    lastEdge.cursor = endCursor
                }
                const firstCursor = firstEdge && firstEdge.cursor
                if (firstCursor && !startCursor) {
                    incoming = deepMerge(incoming, {
                        pageInfo: {
                            startCursor: firstCursor
                        }
                    })
                }
                const lastCursor = lastEdge && lastEdge.cursor
                if (lastCursor && !endCursor) {
                    incoming = deepMerge(incoming, {
                        pageInfo: {
                            endCursor: lastCursor
                        }
                    })
                }
            }
            let prefix = existing.edges
            let suffix = []
            if (args && args.after) {
                const index = prefix.findIndex(function (edge) {
                    return edge.cursor === args.after
                })
                if (index >= 0) {
                    prefix = prefix.slice(0, index + 1)
                }
            } else if (args && args.before) {
                const index = prefix.findIndex(function (edge) {
                    return edge.cursor === args.before
                })
                suffix = index < 0 ? prefix : prefix.slice(index)
                prefix = []
            } else if (incoming.edges) {
                prefix = []
            }
            const edges = [...prefix, ...incomingEdges, ...suffix]
            const pageInfo = assign(
                assign({}, incoming.pageInfo),
                existing.pageInfo
            )
            if (incoming.pageInfo) {
                const _b = incoming.pageInfo
                const hasPreviousPage = _b.hasPreviousPage
                const hasNextPage = _b.hasNextPage
                const startCursor = _b.startCursor
                const endCursor = _b.endCursor

                const extras = rest(_b, [
                    `hasPreviousPage`,
                    `hasNextPage`,
                    `startCursor`,
                    `endCursor`
                ])
                Object.assign(pageInfo, extras)
                if (!prefix.length) {
                    if (undefined !== hasPreviousPage)
                        pageInfo.hasPreviousPage = hasPreviousPage
                    if (undefined !== startCursor)
                        pageInfo.startCursor = startCursor
                }
                if (!suffix.length) {
                    if (undefined !== hasNextPage)
                        pageInfo.hasNextPage = hasNextPage
                    if (undefined !== endCursor) pageInfo.endCursor = endCursor
                }
            }
            return assign(
                assign(assign({}, getExtras(existing)), getExtras(incoming)),
                { edges: edges, pageInfo: pageInfo }
            )
        }
    }
}

const getExtras = (obj) => {
    return rest(obj, notExtras)
}

const notExtras = [`edges`, `pageInfo`]

const makeEmptyData = () => {
    return {
        edges: [],
        pageInfo: {
            hasPreviousPage: false,
            hasNextPage: true,
            startCursor: ``,
            endCursor: ``
        }
    }
}

const rest = (s, e) => {
    const t = {}
    for (const p in s)
        if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
            t[p] = s[p]
    if (s != null && typeof Object.getOwnPropertySymbols === `function`)
        for (
            let i = 0, p = Object.getOwnPropertySymbols(s);
            i < p.length;
            i++
        ) {
            if (
                e.indexOf(p[i]) < 0 &&
                Object.prototype.propertyIsEnumerable.call(s, p[i])
            )
                t[p[i]] = s[p[i]]
        }
    return t
}
